<div class="save-search-error">
  <div class="save-search-error__icon">
    <div class="save-search-error__icon-image">
      <img src="assets/icons/alert.svg" alt="Alert" />
    </div>
  </div>
  <h2 class="save-search-error__title">¿Querés eliminar esta búsqueda?</h2>
  <p class="save-search-error__message">
    La búsqueda será eliminada y no podrás acceder desde el listado de búsquedas
    guardadas.
  </p>
</div>
