import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Icon } from '@app/core/models/icon.model';
import { QRInput } from '@app/core/models/qr-input.model';
import { Subject } from 'rxjs';
import { QrIconComponent } from '@app/components/qr-icon/qr-icon.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'qr-input',
  templateUrl: './qr-input.component.html',
  styleUrls: ['./qr-input.component.scss'],
  standalone: true,
  imports: [QrIconComponent, CommonModule, FormsModule],
})
export class QrInputComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() config!: QRInput;
  value: number | string | undefined; // Valor real que se envia en el @Output

  visibleMaskValue: string | undefined; // Valor que es visible para el usuario final con formato, funciona como una máscara para el usuario, NO es el valor que se envia en @Ouput
  valueSubject: Subject<string | number | undefined> = new Subject();
  @Output() inputChange: EventEmitter<string | number> = new EventEmitter<
    string | number
  >();

  @Input() hasError?: boolean = false;
  @Input() isValid?: boolean = false;
  @Input() isDisabled?: boolean = false;

  @ViewChild('input', { static: false }) input: ElementRef | undefined;

  iconCheck: Icon = { name: Icon.NAME_CHECK, color: Icon.COLOR_PRIMARY };
  iconError: Icon = { name: Icon.NAME_ERROR, color: Icon.COLOR_RED };

  ngOnInit(): void {
    this.valueSubject.subscribe(value => {
      this.inputChange.emit(value);
    });
  }

  ngAfterViewInit(): void {
    if (this.config.isFocus) {
      this.input?.nativeElement.focus(); // Hace focus sobre el input (Te deja el input seleccionado listo para escribir)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']?.currentValue?.value) {
      this.modelChange(changes['config'].currentValue.value);
    }
  }

  ngOnDestroy(): void {
    this.valueSubject.unsubscribe();
  }

  modelChange(value: string): void {
    switch (this.config.format) {
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER: // Números enteros
        //this.value = this.returnOnlyNumber(value);
        this.visibleMaskValue = value;
        this.valueSubject.next(this.value);
        break;
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS: // Números enteros con puntos de miles
        //this.value = this.returnOnlyNumber(value);
        //this.formatVisibleMaskNumberWithDots(this.value);
        this.valueSubject.next(this.value);
        break;
      default:
        this.value = value;
        this.visibleMaskValue = value;
        this.valueSubject.next(this.value);
        break;
    }
  }

  /*--------------------------------------------------------------------*/
  /*--------------------------------------------------------------------*/
  /*--------------------------------------------------------------------*/
  /*--------------------------------------------------------------------*/

  /*
  keydownChange: Se ejecuta al presionar una tecla
  */
  keydownChange(e: KeyboardEvent): void {
    switch (this.config.format) {
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER:
        // Solo números enteros
        // Excepciones
        if (
          (e.key === 'a' && e.ctrlKey === true) || // Permitir: Ctrl+A
          (e.key === 'c' && e.ctrlKey === true) || // Permitir: Ctrl+C
          (e.key === 'v' && e.ctrlKey === true) || // Permitir: Ctrl+V
          (e.key === 'x' && e.ctrlKey === true) || // Permitir: Ctrl+X
          (e.key === 'a' && e.metaKey === true) || // Permitir+A (Mac)
          (e.key === 'c' && e.metaKey === true) || // Permitir: Cmd+C (Mac)
          (e.key === 'v' && e.metaKey === true) || // Permitir: Cmd+V (Mac)
          (e.key === 'x' && e.metaKey === true) || // Permitir: Cmd+X (Mac)
          e.key === 'Tab' || // Permitir: Tab
          e.key === 'Backspace' || // Permitir: Backspace
          e.key === 'Delete' || // Permitir: Delete
          e.key === 'ArrowLeft' || // Permitir: Flecha a la izquierda
          e.key === 'ArrowRight' // Permitir: Flecha a la derecha
        ) {
          return; // dejar que pase, no hago nada
        }
        // Asegurarse que es un número valido
        if (e.key === ' ' || isNaN(Number(e.key))) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        break;
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS:
        // Solo números enteros
        // Excepciones
        if (
          (e.key === 'a' && e.ctrlKey === true) || // Permitir: Ctrl+A
          (e.key === 'c' && e.ctrlKey === true) || // Permitir: Ctrl+C
          (e.key === 'v' && e.ctrlKey === true) || // Permitir: Ctrl+V
          (e.key === 'x' && e.ctrlKey === true) || // Permitir: Ctrl+X
          (e.key === 'a' && e.metaKey === true) || // Permitir+A (Mac)
          (e.key === 'c' && e.metaKey === true) || // Permitir: Cmd+C (Mac)
          (e.key === 'v' && e.metaKey === true) || // Permitir: Cmd+V (Mac)
          (e.key === 'x' && e.metaKey === true) || // Permitir: Cmd+X (Mac)
          e.key === 'Tab' || // Permitir: Tab
          e.key === 'Backspace' || // Permitir: Backspace
          e.key === 'Delete' || // Permitir: Delete
          e.key === 'ArrowLeft' || // Permitir: Flecha a la izquierda
          e.key === 'ArrowRight' // Permitir: Flecha a la derecha
        ) {
          return; // dejar que pase, no hago nada
        }
        // Asegurarse que es un número valido
        if (e.key === ' ' || isNaN(Number(e.key))) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        break;

      default:
        break;
    }
    if (this.config.limitNumberOfCharacters) {
      this.visibleMaskValue = this.visibleMaskValue?.slice(
        0,
        this.config.limitNumberOfCharacters - 1
      );
    }
  }

  /*
  pasteChange: Se ejecuta al pegar algo en el input
  */
  pasteChange(event: ClipboardEvent): void {
    switch (this.config.format) {
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS:
        if (event && event.clipboardData) {
          event.preventDefault();
          const pastedInput: string = event.clipboardData
            .getData('text/plain')
            .replace(/\D/g, ''); // get a digit-only string
          document.execCommand('insertText', false, pastedInput);
        }
    }
  }

  /*
  returnOnlyNumber: Recibe un string y devuelve solo los valores numéricos
  */
  // private returnOnlyNumber(value: string): number {
  //   let number: number | undefined = undefined;
  //   if (value !== undefined && value !== null && value.length != 0) {
  //     number = Number(value?.toString().replace(/\D/g, ''));
  //   }
  //   return number;
  // }

  /*
	formatVisibleMaskNumberWithDots: Agregar a número puntos de miles, ingresa en formato de
  number y se devuelve como un string (Se usa para el valor visual [visibleMaskVale] que ve
  el usuario, funciona como una máscara visual para el usuario)
	*/
  private formatVisibleMaskNumberWithDots(number: number | undefined) {
    if (number !== null && number !== undefined) {
      this.visibleMaskValue = BigInt(number)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      this.visibleMaskValue = undefined;
    }
  }

  /*-------------------------------------------------*/
  /*-------------------------------------------------*/
  /*-------------------------------------------------*/
  /*-------------------------------------------------*/
}
