import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [RouterModule],
})
export class ToastComponent {
  @Input() message: string = 'Se guardó la búsqueda correctamente';
  @Input() text: string = 'Ir a búsquedas';
  @Input() isVisible: boolean = false;
}
