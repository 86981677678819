import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ItemParam } from '@app/core/models/ItemParam';
import { SearchDomain } from '@app/domain/search.domain';
import { QrNumberPipe } from '../../pipes/qr-number.pipe';

@Component({
  selector: 'qr-dropdown-select',
  templateUrl: './qr-dropdown-select.component.html',
  styleUrls: ['./qr-dropdown-select.component.scss'],
  standalone: true,
  imports: [CommonModule, L10nTranslationModule, QrNumberPipe],
})
export class QrDropdownSelectComponent implements OnInit, OnDestroy {
  @Input() options: ItemParam[] = [];
  @Input() config: any;
  @Input() linkedDropdown: boolean = false;

  isSelected = false;
  isOpen = false;
  array: any[] = [];

  isNumberRange = false;
  min: number = 0;
  max: number = 0;

  listingLoadingSubscription: Subscription | undefined;
  reportResultLoadingSubcription: Subscription | undefined;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public searchDomain: SearchDomain
  ) {}

  ngOnInit(): void {
    this.listingLoadingSubscription =
      this.searchDomain.listingsLoading$.subscribe((loading: boolean) => {
        if (!loading) this.detectType();
      });
    this.reportResultLoadingSubcription =
      this.searchDomain.isReportResultLoading$.subscribe(
        (isLoading: boolean) => {
          if (!isLoading) {
            this.detectType();
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.listingLoadingSubscription?.unsubscribe();
    this.reportResultLoadingSubcription?.unsubscribe();
  }

  detectType(): void {
    this.array = [];

    switch (this.config.placeholder) {
      case 'list.propertyType':
        if (this.searchDomain.reportResult.propertyType.length > 1) {
          this.isSelected = true;
          let string = '';
          this.searchDomain.reportResult.propertyType.forEach(
            (element: any) => {
              string = 'property-type.' + element;
              if (!this.array.includes(string)) {
                this.array.push(string);
              }
            }
          );
        } else if (this.searchDomain.reportResult.propertyType.length == 1) {
          this.isSelected = true;

          this.array.push(
            'property-type.' + this.searchDomain.reportResult.propertyType[0]
          );
        } else {
          this.isSelected = false;
        }

        break;

      // BOTON DE TIPO DE OPERACION
      case 'list.operationType':
        if (this.searchDomain.reportResult.operationType.length > 1) {
          this.isSelected = true;
          let string = '';

          this.searchDomain.reportResult.operationType.forEach(
            (element: any) => {
              string = 'list.' + element;
              if (!this.array.includes(string)) {
                this.array.push(string);
              }
            }
          );
        } else if (this.searchDomain.reportResult.operationType.length == 1) {
          this.isSelected = true;
          this.array.push(
            'list.' + this.searchDomain.reportResult.operationType[0]
          );
        } else {
          this.isSelected = false;
        }

        break;

      // BOTON STAGE
      case 'list.stage':
        const cases = [
          { id: 1, value: 'presale' },
          { id: 2, value: 'in_construction' },
          { id: 3, value: 'pozo' },
          { id: 4, value: 'finished' },
        ];
        if (
          this.searchDomain.filterStage &&
          this.searchDomain.filterStage.length > 1
        ) {
          this.isSelected = true;
          let string = '';

          this.searchDomain.filterStage.forEach(element => {
            if (element) {
              const item = cases.find((e: any) => e.id == element);
              string = 'list.' + item?.value;
              this.array.push(string);
            }
          });
        } else if (
          this.searchDomain.filterStage &&
          this.searchDomain.filterStage.length == 1
        ) {
          if (this.searchDomain.filterStage[0] == 0) {
            this.isSelected = false;
          } else {
            this.isSelected = true;
            const item = cases.find(
              (e: any) => e.id == this.searchDomain.filterStage[0]
            );
            this.array.push('list.' + item?.value);
          }
        } else {
          this.isSelected = false;
        }

        break;

      // BOTON ORDER BY
      case 'list.orderBy':
        break;

      case 'list.price': // Precio
        if (
          (this.searchDomain.filterPrice.min &&
            this.searchDomain.filterPrice.min > 0) ||
          (this.searchDomain.filterPrice.max &&
            this.searchDomain.filterPrice.max > 0)
        ) {
          this.isNumberRange = true;
          this.isSelected = true;
          if (
            this.searchDomain.filterPrice.min &&
            this.searchDomain.filterPrice.min > 0
          ) {
            this.min = this.searchDomain.filterPrice.min;
          }
          if (
            this.searchDomain.filterPrice.max &&
            this.searchDomain.filterPrice.max > 0
          ) {
            this.max = this.searchDomain.filterPrice.max;
          }
        } else {
          this.isSelected = true;
          this.isNumberRange = true;
        }
        break;
    }
    // TODO: Terminar parseo de datos segun tipo
  }
}
