<div id="more-than-moving-dialog-container">
  <div id="title">
    <h1>
      Si querés vender o alquilar tu propiedad, contá con un equipo RE/MAX,
      estamos para ayudarte.
    </h1>
  </div>
  <div id="item-wrapper">
    @for (img of this.backgroundImage; track img) {
      <div
        class="item-container"
        [ngStyle]="{
          'background-image': 'url(' + img + ')',
        }"></div>
    }
  </div>
  <div id="disclaimer">
    <h1>
      Las oficinas adheridas a la red RE/MAX cumplen estrictamente con las
      normas y recomendaciones dictadas por el Ministerio de Salud de la Nación
      y demás Autoridades pertinentes con relación al COVID-19.
    </h1>
  </div>
</div>
