import { string2Number } from '@app/core/utils/string2number';
export class QrFilterPrice {
  private _currencyId = 1;
  private _min = 0;
  private _max: number | null = null;

  get currencyId(): number {
    return this._currencyId !== null ? this._currencyId : 1;
  }

  set currencyId(value: number | null) {
    if (value !== null && value > 0) {
      this._currencyId = value;
    }
  }

  get min(): number {
    return this._min != null ? this._min : 0;
  }

  set min(value: number | null) {
    this._min = string2Number(value);
  }

  get max(): number {
    return this._max != null ? this._max : 0;
  }

  set max(value: number | null) {
    this._max = string2Number(value);
  }

  /*
	copy(): QrFilterPrice {
		const qrFilterPrice = new QrFilterPrice();
		qrFilterPrice.currencyId = this.currencyId;
		qrFilterPrice.min = this.min;
		qrFilterPrice.max = this.max;
		return qrFilterPrice;
	}
  */
  isEqual(qrFilterPrice: QrFilterPrice): boolean {
    if (qrFilterPrice == null) {
      return false;
    } else {
      return (
        qrFilterPrice.max == this.max &&
        qrFilterPrice.min == this.min &&
        qrFilterPrice.currencyId == this.currencyId
      );
    }
  }

  clone(): QrFilterPrice {
    const filter = new QrFilterPrice();
    filter.currencyId = this.currencyId;
    filter.min = this.min;
    filter.max = this.max;
    return filter;
  }
}
