<div id="topbar">
  <div id="title">
    <h4>{{ 'home.kind-of-property' | translate: locale.language }}</h4>
  </div>
  <div id="illustration"></div>
</div>
<div>
  <qr-checkbox-group
    [checkboxGroup]="this.checkboxGroup1"
    selectionMode="property-home-mobile">
  </qr-checkbox-group>
  <qr-checkbox-group
    [checkboxGroup]="this.checkboxGroup2"
    selectionMode="property-home-mobile"></qr-checkbox-group>
  <div id="subcheckboxs">
    <ul>
      @for (item of this.propertyList; track item) {
        <li>
          <mat-checkbox
            [(ngModel)]="item.selected"
            (change)="onSelect(item.id)"
            color="primary">
            {{ 'property-type.' + item.value | translate: locale.language }}
          </mat-checkbox>
        </li>
      }
    </ul>
  </div>
</div>
