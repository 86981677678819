import { Component, Inject } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { ParamsDomain } from '@app/domain/params.domain';
import { SearchDomain } from '@app/domain/search.domain';
import { environment } from '@base/environments/environment';
import { CheckboxGroup } from '@app/core/models/checkbox-group.model';
import { ItemParam } from '@app/core/models/ItemParam';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { QrCheckboxGroupComponent } from '../../qr-checkbox-group/qr-checkbox-group.component';

@Component({
  selector: 'app-qr-property-type-home',
  templateUrl: './qr-property-type-home.component.html',
  styleUrls: ['./qr-property-type-home.component.scss'],
  standalone: true,
  imports: [
    L10nTranslatePipe,
    MatCheckboxModule,
    FormsModule,
    QrCheckboxGroupComponent,
  ],
})
export class QrPropertyTypeHomeComponent {
  propertyList: any[] = [];
  checkboxGroup1: CheckboxGroup = {
    id: -1,
    value: 'departamento',
    selected: false,
    subcheckboxs: [],
  };

  checkboxGroup2: CheckboxGroup = {
    id: -2,
    value: 'casa',
    selected: false,
    subcheckboxs: [],
  };

  selection: number[] = [];
  listingTypes$: Promise<ItemParam[]>;
  casaIds: number[] = [];
  departamentosIds: number[] = [];
  INNER_CASA_ID = 100;
  INNER_DEPARTAMENTO_ID = 101;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramsDomain: ParamsDomain,
    public searchDomain: SearchDomain
  ) {
    if (environment.node == 'uy') {
      this.checkboxGroup1.value = 'departamento';
    }

    this.listingTypes$ = this.paramsDomain.getListingsTypes();

    this.listingTypes$.then(response => {
      response.forEach(element => {
        if (element.order < 2) {
          const item = {
            id: element.id,
            value: element.value,
            selected: false,
          };
          this.checkboxGroup1.subcheckboxs?.push(item);
        }

        if (element.order < 6 && element.order >= 5) {
          const item = {
            id: element.id,
            value: element.value,
            selected: false,
          };
          this.checkboxGroup2.subcheckboxs?.push(item);
        }

        if (element.order == 3) {
          const item = {
            id: element.id,
            value: element.value,
            selected: false,
          };
          this.propertyList.push(item);
        }

        if (element.order >= 6) {
          const item = {
            id: element.id,
            value: element.value,
            selected: false,
          };
          this.propertyList.push(item);
        }
      });
    });
  }

  onSelect(id: any) {
    this.selection = this.searchDomain.filterListingType;
    const index = this.selection.findIndex(element => element == id);

    if (index >= 0) {
      this.selection.splice(index, 1);
    } else {
      this.selection.push(id);
    }

    this.searchDomain.filterListingType = [...new Set(this.selection)];
  }
}
