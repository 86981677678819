import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Constants } from '@base/src/app/core/constants/constants';
import { environment } from '@base/environments/environment';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { ItemParam } from '@app/core/models/ItemParam';
import { QrFilterPrice } from '@app/core/models/qr-filter-price';
import { QrInputNumberComponent } from '../qr-input-number/qr-input-number.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'qr-filter-price-number-range',
  templateUrl: './qr-filter-price-number-range.component.html',
  styleUrls: ['./qr-filter-price-number-range.component.scss'],
  standalone: true,
  imports: [QrInputNumberComponent, MatSelectModule, FormsModule],
})
export class QrFilterPriceNumberRangeComponent implements OnDestroy {
  placeholder = 'Moneda';
  @Input() currencies: ItemParam[] = [];

  isEc = environment.node == Constants.NODE_ECUADOR;

  @Output() onchange = new EventEmitter<QrFilterPrice>();
  _dataSelected: QrFilterPrice = new QrFilterPrice();
  private subscription: Subscription | undefined;

  @Input() set selected$(value: Observable<QrFilterPrice>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (!Object.is(data, this._dataSelected)) {
          this._dataSelected = data.clone();
        }
      });
    }
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  //ngOnChanges(): void {}

  /*
	updateDataForParent: Envia al componente padre el estado de lo botones
		*/
  updateCurrency(event: MatSelectChange) {
    this._dataSelected.currencyId = event.value;
    this.dispathEvent();
  }

  updateMax(event: string | null) {
    this._dataSelected.max = event !== null ? parseFloat(event) : 0;
    this.dispathEvent();
  }

  updateMin(event: string | null) {
    this._dataSelected.min = event !== null ? parseFloat(event) : 0;
    this.dispathEvent();
  }

  dispathEvent(): void {
    this.onchange.emit(this._dataSelected.clone());
  }
}
