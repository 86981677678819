import { Injectable } from '@angular/core';
import { ConditionsService } from '../services/conditions.service';
import { OpertionsService } from '../services/opertations.service';
import { CurrenciesService } from '../services/currencies.service';
import { ListingTypesService } from '../services/listing-types.service';
import { Constants } from '../core/constants/constants';
import { FeaturesService } from '../services/listing-features.service';
import { ListingAndEntrepreneurshipService } from '../services/listing-and-entrepreneurship.service';
import { IQrFilterType } from '../core/enum/IQrFilterType';
import { IQrPage } from '../core/models/IQrPage';
import { ItemParam } from '../core/models/ItemParam';
import { Listing } from '../core/models/listing.model';
import { QrFilter } from '../core/models/qr-filter';
import { QrFilterCustom } from '../core/models/qr-filter-custom';

@Injectable({ providedIn: 'root' })
export class ParamsDomain {
  private _conditionTypes: ItemParam[] = [];
  private _featureTypes: ItemParam[] = [];
  private _operationTypes: ItemParam[] = [];
  private _listingTypes: ItemParam[] = [];
  private _currencyTypes: ItemParam[] = [];
  private _opportunitiesListing: Listing[] = [];
  private _yearsBuildTypes: ItemParam[] = [
    new ItemParam(1, 'upTo5years'),
    new ItemParam(2, 'between5and10years'),
    new ItemParam(3, 'between10and20years'),
    new ItemParam(4, 'between20and50years'),
    new ItemParam(5, 'moreThan50years'),
  ];

  private _roomsTypes: ItemParam[] = [
    new ItemParam(1, '1'),
    new ItemParam(2, '2'),
    new ItemParam(3, '3'),
    new ItemParam(4, '4'),
    new ItemParam(5, '5'),
    new ItemParam(6, '+6'),
  ];

  private _bathroomsTypes: ItemParam[] = [
    new ItemParam(1, '1'),
    new ItemParam(2, '2'),
    new ItemParam(3, '3'),
    new ItemParam(4, '+4'),
  ];

  private _bedroomsTypes: ItemParam[] = [
    new ItemParam(1, '1'),
    new ItemParam(2, '2'),
    new ItemParam(3, '3'),
    new ItemParam(4, '4'),
    new ItemParam(5, '+5'),
  ];

  private _parkingSpaces: ItemParam[] = [
    new ItemParam(1, '1'),
    new ItemParam(2, '2'),
    new ItemParam(3, '3'),
    new ItemParam(4, '4'),
    new ItemParam(5, '+5'),
  ];

  private _ordersBy: ItemParam[] = [
    new ItemParam(1, 'priceAsc', '+priceUsd'),
    new ItemParam(2, 'priceDesc', '-priceUsd'),
  ];

  private _oportunitiesTypes: ItemParam[] = [
    new ItemParam(1, 'muy_destacado'),
    new ItemParam(2, 'destacado'),
    new ItemParam(3, 'regular'),
    new ItemParam(4, 'no_destacado'),
  ];

  private _stage: ItemParam[] = [
    new ItemParam(1, 'presale'),
    new ItemParam(2, 'in_construction'),
    new ItemParam(3, 'pozo'),
    new ItemParam(4, 'finished'),
  ];

  constructor(
    private listingAndEntrepreneurshipService: ListingAndEntrepreneurshipService,
    private operationsService: OpertionsService,
    private conditionsService: ConditionsService,
    private featuresService: FeaturesService,
    private listingTypesService: ListingTypesService,
    private currenciesService: CurrenciesService
  ) {}

  public async getOpportunitiesListing(): Promise<Listing[]> {
    if (
      this._opportunitiesListing === undefined ||
      this._opportunitiesListing === null ||
      this._opportunitiesListing.length === 0
    ) {
      const FILTER: QrFilter = new QrFilter(0, 3);
      FILTER.sorts = ['-id'];

      const opportunityFilter = new QrFilterCustom(
        IQrFilterType.eq,
        'opportunityId',
        Constants.OPORTUNITY_ID_REGULAR
      );

      const entrepreneurshipFilter = new QrFilterCustom(
        IQrFilterType.eq,
        'entrepreneurship',
        false
      );

      FILTER.addFilter(entrepreneurshipFilter);
      FILTER.addFilter(opportunityFilter);

      const QUERY: string = FILTER.toQueryStringBe();

      return new Promise<Listing[]>(resolve => {
        this.listingAndEntrepreneurshipService.getParams(QUERY).subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              const qrPage: IQrPage = response.data;
              this._opportunitiesListing = qrPage.data;
              resolve(this._opportunitiesListing);
            } else {
              this._opportunitiesListing = [];
              console.warn(response);
              resolve(this._opportunitiesListing);
            }
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
      });
    } else {
      return this._opportunitiesListing;
    }
  }

  public async getListingsTypes(): Promise<ItemParam[]> {
    if (
      this._listingTypes === undefined ||
      this._listingTypes === null ||
      this._listingTypes.length === 0
    ) {
      const FILTER: QrFilter = new QrFilter(0, 30);
      FILTER.sorts = ['+order'];

      const QUERY: string = FILTER.toQueryStringBe();

      return new Promise<ItemParam[]>(resolve => {
        this.listingTypesService.getParams(QUERY).subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              const qrPage: IQrPage = response.data;
              this._listingTypes = qrPage.data;
              resolve(this._listingTypes);
            } else {
              this._listingTypes = [];
              console.warn(response);
              resolve(this._listingTypes);
            }
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
      });
    } else {
      return this._listingTypes;
    }
  }

  public async getFeaturesTypes(): Promise<ItemParam[]> {
    if (
      this._featureTypes === undefined ||
      this._featureTypes === null ||
      this._featureTypes.length === 0
    ) {
      const FILTER: QrFilter = new QrFilter(0, 120); // 102 en tabla
      FILTER.sorts = ['+value'];

      const QUERY: string = FILTER.toQueryStringBe();

      return new Promise<ItemParam[]>(resolve => {
        this.featuresService.getParams(QUERY).subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              const qrPage: IQrPage = response.data;
              this._featureTypes = qrPage.data;
              resolve(this._featureTypes);
            } else {
              this._featureTypes = [];
              console.warn(response);
              resolve(this._featureTypes);
            }
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
      });
    } else {
      return this._featureTypes;
    }
  }

  public async getCondicionsTypes(): Promise<ItemParam[]> {
    if (
      this._conditionTypes === undefined ||
      this._conditionTypes === null ||
      this._conditionTypes.length === 0
    ) {
      const FILTER: QrFilter = new QrFilter(0, 15); // 8 en tabla
      FILTER.sorts = ['+id'];

      const QUERY: string = FILTER.toQueryStringBe();

      return new Promise<ItemParam[]>(resolve => {
        this.conditionsService.getParams(QUERY).subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              const qrPage: IQrPage = response.data;
              this._conditionTypes = qrPage.data;
              resolve(this._conditionTypes);
            } else {
              this._conditionTypes = [];
              console.warn(response);
              resolve(this._conditionTypes);
            }
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
      });
    } else {
      return this._conditionTypes;
    }
  }

  public async getOperationsTypes(): Promise<ItemParam[]> {
    if (
      this._operationTypes === undefined ||
      this._operationTypes === null ||
      this._operationTypes.length === 0
    ) {
      const FILTER: QrFilter = new QrFilter(0, 5); // 3 en tabla
      FILTER.sorts = ['+id'];
      const QUERY: string = FILTER.toQueryStringBe();

      return new Promise<ItemParam[]>(resolve => {
        this.operationsService.getParams(QUERY).subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              const qrPage: IQrPage = response.data;
              this._operationTypes = qrPage.data;
              resolve(this._operationTypes);
            } else {
              this._operationTypes = [];
              console.warn(response);
              resolve(this._operationTypes);
            }
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
      });
    } else {
      return this._operationTypes;
    }
  }

  public async getCurrenciesTypes(): Promise<ItemParam[]> {
    if (
      this._currencyTypes === undefined ||
      this._currencyTypes === null ||
      this._currencyTypes.length === 0
    ) {
      const FILTER: QrFilter = new QrFilter(0, 5); // 3 en tabla
      FILTER.sorts = ['+id'];

      const QUERY: string = FILTER.toQueryStringBe();

      return new Promise<ItemParam[]>(resolve => {
        this.currenciesService.getParams(QUERY).subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              const qrPage: IQrPage = response.data;
              this._currencyTypes = qrPage.data;
              resolve(this._currencyTypes);
            } else {
              this._currencyTypes = [];
              console.warn(response);
              resolve(this._currencyTypes);
            }
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
      });
    } else {
      return this._currencyTypes;
    }
  }

  public async getYearsBuildTypes(): Promise<ItemParam[]> {
    return this._yearsBuildTypes;
  }

  public async getRoomsTypes(): Promise<ItemParam[]> {
    return this._roomsTypes;
  }

  public async getBathRoomsTypes(): Promise<ItemParam[]> {
    return this._bathroomsTypes;
  }

  public async getBedroomsTypes(): Promise<ItemParam[]> {
    return this._bedroomsTypes;
  }

  public async getParkingSpacesTypes(): Promise<ItemParam[]> {
    return this._parkingSpaces;
  }

  public async getOrdersBy(): Promise<ItemParam[]> {
    return this._ordersBy;
  }

  public async getStage(): Promise<ItemParam[]> {
    return this._stage;
  }

  public async getOportunitiesTypes(): Promise<ItemParam[]> {
    return this._oportunitiesTypes;
  }

  /*
	public getYearsBuildTypes(): ItemParam[] {
		return this._yearsBuildTypes;
	}

	public getRoomsTypes(): ItemParam[] {
		return this._roomsTypes;
	}

	public getBathRoomsTypes(): ItemParam[] {
		return this._bathroomsTypes;
	}
	public getBedroomsTypes(): ItemParam[] {
		return this._bedroomsTypes;
	}
	public getParkingSpacesTypes(): ItemParam[] {
		return this._parkingSpaces;
  }
  */
}
