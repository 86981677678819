import { string2Number } from '../utils/string2number';

export class QrFilterRange {
  private _min = 0;
  private _max: number = 0;

  get min(): number {
    return this._min != null ? this._min : 0;
  }

  set min(value: number) {
    this._min = string2Number(value);
  }

  get max(): number {
    return this._max != null ? this._max : 0;
  }

  set max(value: number) {
    this._max = string2Number(value);
  }

  /*
	copy(): QrFilterRange {
		const qrFilterRange = new QrFilterRange();
		qrFilterRange.min = this.min;
		qrFilterRange.max = this.max;
		return qrFilterRange;
  }
  */

  isEqual(qrFilterRange: QrFilterRange): boolean {
    return qrFilterRange.max == this.max && qrFilterRange.min == this.min;
  }

  clone(): QrFilterRange {
    const filter: QrFilterRange = new QrFilterRange();
    filter.max = this.max;
    filter.min = this.min;
    return filter;
  }
}
