import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  OnInit,
} from '@angular/core';
import { QRInput } from '@base/src/app/core/models/qr-input.model';
import { QrInputComponent } from '../../qr-input/qr-input.component';

@Component({
  selector: 'app-search-save',
  templateUrl: './search-save.component.html',
  styleUrls: ['./search-save.component.scss'],
  standalone: true,
  imports: [QrInputComponent],
})
export class SaveSearchComponent implements OnInit {
  @Output() dataForParent = new EventEmitter<any>();
  @Input() searchName: string = '';

  public textInputWithValue: QRInput = {
    label: '',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'save-search',
    id: 'save-search',
    placeholder: 'Ej. Departamentos en Belgrano',
    hint: 'Ingresá hasta 120 caracteres.',
    error: 'Ingrese un nombre valido',
    limitNumberOfCharacters: 120,
    isAutocompleteSuggestionsHidden: true,
  };

  private currentUrl: string = '';
  private linkUrl: string = '';

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit(): void {
    this.saveSearch();
  }

  public inputChange(event: string | number): void {
    if (event) {
      this.dataForParent.emit(event);
    } else {
      this.dataForParent.emit(undefined);
    }
  }

  public saveSearch(): void {
    if (isPlatformBrowser(this.platformId)) {
      const URL: string = window.location.search;
      const QUERY: string = URL.substring(1);
      this.linkUrl = window.location.href;
      this.currentUrl = QUERY;
    }
  }
}
