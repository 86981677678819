import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';

import { Observable, Subscription } from 'rxjs';

import { Constants } from '@base/src/app/core/constants/constants';
import { ItemParam } from '@app/core/models/ItemParam';
import {
  IButtonConfig,
  QrButtonComponent,
} from '../qr-button/qr-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'qr-filter-buttons',
  templateUrl: './qr-filter-buttons.component.html',
  styleUrls: ['./qr-filter-buttons.component.scss'],
  standalone: true,
  imports: [QrButtonComponent, CommonModule],
})
export class QrFilterButtonsComponent implements OnInit, OnDestroy, OnChanges {
  //QUITAR CUANDO SE TERMINE EL REFACTOR
  @Input() buttonsText!: string[];
  @Output() dataChange: EventEmitter<ItemParam[]> = new EventEmitter<
    ItemParam[]
  >();

  buttons: any[] = [];
  @Input() isMultipleSelection = true;
  // Activar/Desactivar selección multiple de botones
  @Input() oneColumn: boolean = true;
  _dataSelected: any[] = [];
  // Forzar botones a mostrarse en una sola columna
  @Output() onchange: EventEmitter<any[]> = new EventEmitter<string[]>();
  isLessThanThreeButtons = false;
  private subscription: Subscription | undefined;

  //QUITAR CUANDO SE TERMINE EL REFACTOR
  private _data: ItemParam[] = [];

  // Nombre de variables i18n de botones

  @Input() set selected(value: any[]) {
    if (this._dataSelected === null || this._dataSelected === undefined) {
      if (value !== undefined && value !== null && value.length > 0) {
        this._dataSelected = value;
      } else {
        this._dataSelected = [];
      }
    }
  }

  @Input()
  set options(value: ItemParam[]) {
    if (value != undefined && value != null) {
      this._data = value;
      this.init();
    }
  }

  @Input() set selected$(value: Observable<any[]>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (!Object.is(data, this._dataSelected)) {
          // this._dataSelected = data;
          this._dataSelected = [...data];

          this.init();
        }
      });
    }
  }

  // Define si tiene menos de 3 botones y afecta los estilos

  constructor(
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit(): void {
    if (this.oneColumn == undefined) {
      this.oneColumn = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  /*
  ngOnChanges: Detecta que hubo un cambio en el componente
  */
  ngOnChanges(): void {
    if (this._dataSelected && this._dataSelected.length == 0) {
      // Detecto caso de limpiar filtros
      for (let x = 0; x < this.buttons.length; x++) {
        const button = this.buttons[x];
        button.selected = false;
        this.buttons[x] = Object.assign({}, button); // Actualizar objeto
      }
      this._dataSelected = [];
    }
  }

  /*
  updateDataForParent: Envia al componente padre que botones estan seleccionados
  */
  updateDataForParent(buttonChange: IButtonConfig): void {
    if (this.isMultipleSelection == false) {
      for (let i = 0; i < this.buttons.length; i++) {
        const button = this.buttons[i];
        if (button.id !== buttonChange.id) {
          // Si no hay selección multiple, des-seleccionar el resto de botones
          button.selected = false;
          this.buttons[i] = Object.assign({}, button); // Actualizar objeto
        }
      }

      this._dataSelected = [];
    }

    if (buttonChange.selected) {
      this.addSelected(buttonChange.id);
    } else {
      this.removeSelected(buttonChange.id);
    }
    this.onchange.emit([...this._dataSelected]);
  }

  private init(): void {
    this.buttons = [];
    this.translation.onChange().subscribe({
      next: () => {
        this._data.forEach((button: ItemParam) => {
          const isSelected =
            this._dataSelected &&
            this._dataSelected.findIndex(item => item == button.id) >= 0;
          const buttonObj: IButtonConfig = {
            // Creación dinámica de objetos de botones
            style: Constants.BUTTON_COLOR_GREY_BORDER,
            height: Constants.BUTTON_HEIGHT_48PX,
            //text: i18n[button.value] || button.value,
            text:
              this.translation.translate('list.' + button.value) ||
              button.value,
            id: button.id,
            selected: isSelected,
            isDisabled: false,
            changeStyleOnSelected: true,
            styleOnSelected: Constants.BUTTON_COLOR_PRIMARY_SOLID,
          };
          this.buttons.push(buttonObj);
        });

        if (!this.oneColumn) {
          this.buttons.length <= 3
            ? (this.isLessThanThreeButtons = true)
            : (this.isLessThanThreeButtons = false);
        }
      },
    });
  }

  private addSelected(id: number): void {
    if (
      this._dataSelected !== undefined &&
      this._dataSelected !== null &&
      this._dataSelected.length > 0
    ) {
      const index: number = this._dataSelected.findIndex(item => item == id);
      if (index < 0) {
        const index: number = this._data.findIndex(item => item.id == id);
        this._dataSelected.push(this._data[index].id);
      }
    } else {
      this._dataSelected = [];
      const index = this._data.findIndex(item => item.id == id);
      this._dataSelected.push(this._data[index].id);
    }
  }

  private removeSelected(id: number): void {
    if (this._dataSelected !== null && this._dataSelected.length > 0) {
      const index: number = this._dataSelected.findIndex(item => item == id);
      if (index >= 0) {
        this._dataSelected = this._dataSelected.filter(item => {
          return item != id;
        });
      }
    } else {
      this._dataSelected = [];
    }
  }
}
