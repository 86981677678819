import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { environment } from '@base/environments/environment';
import { IGeoResult } from './igeo-result';

@Injectable({
  providedIn: 'root',
})
export class GeoSearchService {
  constructor(private http: HttpClient) {}

  geoSearch(value: string, level = 1): Observable<IQrResponse<IGeoResult>> {
    const httpOptions = {
      params: new HttpParams().set('level', level.toString()),
    };
    return this.http
      .get<
        IQrResponse<IGeoResult>
      >(environment.apiUrl + 'search/findAll/' + value, httpOptions)
      .pipe(retry(3), catchError(this.handleError<any>('geoSearch')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(message);
  }
}
