<!-- Lista desplegable: Tipo -->
<div class="content">
  @if (!this.isEc) {
    <mat-form-field
      class="mat-form-field-white-bg"
      appearance="outline"
      id="type">
      <mat-select
        (selectionChange)="updateCurrency($event)"
        [placeholder]="placeholder"
        [ngModel]="this._dataSelected.currencyId"
        name="type">
        @for (type of currencies; track type) {
          <mat-option [value]="type.id">
            {{ type.value }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <!-- Campo: Desde -->
  <div class="from-to-content">
    <div class="from-content">
      <p class="paragraph-01-bold">Desde</p>
      <qr-input-number
        id="from"
        [valueNumber]="this._dataSelected.min.toString()"
        (onchange)="updateMin($event)">
      </qr-input-number>
    </div>

    <!-- Campo: Hasta -->
    <div class="to-content">
      <p class="paragraph-01-bold">Hasta</p>
      <qr-input-number
        id="to"
        [valueNumber]="this._dataSelected.max.toString()"
        (onchange)="updateMax($event)">
      </qr-input-number>
    </div>
  </div>
</div>
