<!-- Campo: Desde -->
<div class="from-content">
  <p class="paragraph-01-bold">Desde</p>
  <qr-input-number
    id="from"
    [valueNumber]="_dataSelected.min.toString()"
    [hint]="hint"
    (onchange)="updateFrom($event)">
  </qr-input-number>
</div>

<!-- Campo: Hasta -->
<div class="to-content">
  <p class="paragraph-01-bold">Hasta</p>
  <qr-input-number
    id="from"
    [valueNumber]="_dataSelected.max.toString()"
    [hint]="hint"
    (onchange)="updateTo($event)">
  </qr-input-number>
</div>
