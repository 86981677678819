import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '@base/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { GeoSearch } from '../components/geo-search/geo-search.model';
import { Constants } from '../core/constants/constants';
import { IQrFilterType } from '../core/enum/IQrFilterType';
import { ListingAndEntrepreneurship } from '../core/interfaces/listing-and-entrepreneurship.interface';
import { Entrepreneurship } from '../core/models/entrepreneurship.model';
import { IQrPage } from '../core/models/IQrPage';
import { IQrResponse } from '../core/models/IQrResponse';
import { ItemParam } from '../core/models/ItemParam';
import { Listing } from '../core/models/listing.model';
import { Maps } from '../core/models/maps.model';
import { QrFilter } from '../core/models/qr-filter';
import { QrFilterCustom } from '../core/models/qr-filter-custom';
import { QrFilterPrice } from '../core/models/qr-filter-price';
import { QrFilterRange } from '../core/models/QrFilterRange';
import { arraysIsEqual } from '../core/utils/arrayUtils';
import { ListingAndEntrepreneurshipService } from '../services/listing-and-entrepreneurship.service';
import { MapService } from '../services/map.service';
import { ParamsDomain } from './params.domain';

@Injectable({ providedIn: 'root' })
export class SearchDomain {
  dataArray = {} as any;
  isEntrepreneurship: boolean = false;
  maxPerPage: number = 24;
  public operationTypes: ItemParam[] = [];

  //QrFilter
  public qrFilter = new QrFilter(0, this.maxPerPage);
  public listingsLoading$ = new BehaviorSubject(true);
  public entrepreneurshipLoading$ = new BehaviorSubject(true);
  public isResultsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<any>(
    true
  );

  public listingAndEntrepreneurshipMapLoading$ = new BehaviorSubject<any>(true);
  //-------------------------------------
  public isOnlyEntrepreneurshipResults$ = new BehaviorSubject<any>(false);
  // GET INFO RESULT
  public isReportResultLoading$ = new BehaviorSubject<any>(true);
  //-------------------------------------
  public filterOperation$ = new BehaviorSubject<any>([1]);
  //-------------------------------------
  public filterListingType$ = new BehaviorSubject<any>([]);
  // Filtro: ¿Excluir emprendimientos en los resultados?
  public filterAreEntrepreneurshipsExcluded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  //indica que el titulo del search domain ya se haya cargado, evita recargar el titulo
  public checkedTitle: boolean = false;

  // FILTROS STAGE
  public filterStage$ = new BehaviorSubject([0, 1, 2, 3, 4]);
  // FILTER COUNT RESPONSE
  public filterCountResponse$ = new BehaviorSubject<number | null>(null);
  // El internal_id de Office
  privatefilterOfficeIdExecState: string = '';
  //-------------------------------------
  public filterOfficeId$ = new BehaviorSubject<any>(null);
  //-------------------------------------
  public filterCondition$ = new BehaviorSubject<any>([]);
  //-------------------------------------
  public filterFeatures$ = new BehaviorSubject<any>([]);
  //-------------------------------------
  public filterPrice$ = new BehaviorSubject<any>(new QrFilterPrice());
  //-------------------------------------
  public filterExpense$ = new BehaviorSubject<any>(new QrFilterPrice());
  //-------------------------------------
  public filterYearBuild$ = new BehaviorSubject<any[]>([]);
  public filterRooms$ = new BehaviorSubject<any[]>([]);
  public filterBathrooms$ = new BehaviorSubject<any[]>([]);
  public filterBedrooms$ = new BehaviorSubject<any[]>([]);
  public filterParkingSpace$ = new BehaviorSubject<any[]>([]);
  public filterDimensionBuilt$ = new BehaviorSubject<any>(new QrFilterRange());
  public filterDimensionLand$ = new BehaviorSubject<any>(new QrFilterRange());
  //dimensionCovered
  public filterDimensionCovered$ = new BehaviorSubject(new QrFilterRange());
  //-------------------------------------
  public filterAptCredit$ = new BehaviorSubject<any>(false);
  //-------------------------------------
  public filterAptProfessionalUse$ = new BehaviorSubject<any>(false);
  //-------------------------------------
  public filterRemaxCollection$ = new BehaviorSubject<any>(false);
  //-------------------------------------
  public filterOpportunity$ = new BehaviorSubject<any>([]);
  //-------------------------------------
  public filterGeoList$ = new BehaviorSubject<any>([]);
  //-------------------------------------
  public filterPoligono$ = new BehaviorSubject<any>(null);
  //-------------------------------------
  public filterOpportunitiesType$ = new BehaviorSubject<any>([]);
  //-------------------------------------
  public filterOrderBy$ = new BehaviorSubject<any>([]);
  //-------------------------------------
  public filterCount$ = new BehaviorSubject<any>(0);
  private _totalItem = 0;

  private _totalPages = 0;

  private pageExecState = -1;

  private pageSizeExecState = -1;

  // LISTING
  private _listings: Listing[] = [];

  //-------------------------------------
  private _entrepreneurships: Entrepreneurship[] = [];

  private _listingAndEntrepreneurship: ListingAndEntrepreneurship[] = [];

  private _listingAndEntrepreneurshipMap: Maps[] = [];

  //-------------------------------------
  private _reportResult: any = {
    quantity: 0,
    propertyType: [],
    operationType: [],
    stage: [],
    ubication: '',
    orderBy: [],
    opportunity: false,
    titleText: '',
  };

  // OPERATION
  private filterOperationExecState: number[] = [1];
  // LISTINGTYPES
  private filterListingTypeExecState: number[] = [];
  //-------------------------------------
  private filterAreEntrepreneurshipsExcludedExecState: boolean = false;
  //-------------------------------------
  private filterStageExecState: number[] = [0, 1, 2, 3, 4];
  //-------------------------------------
  private filterCountResponseExecState: number | null = null;
  // ------------------------------------
  private _officeName: string = '';
  // CONDITION
  private filterConditionExecState: number[] = [];
  // FEATURES
  private filterFeaturesExecState: number[] = [];
  // PRICE
  private filterPriceExecState: QrFilterPrice = new QrFilterPrice();
  // EXPENSES
  private filterExpenseExecState: QrFilterPrice = new QrFilterPrice();
  // YEARBYUILD
  private filterYearBuildExecState: number[] = [];
  // ROOMS
  private filterRoomsExecState: number[] = [];
  // BATHROOMS
  private filterBathroomsExecState: number[] = [];
  // BEDROOMS
  private filterBedroomsExecState: number[] = [];
  // PARKINGS
  private filterParkingSpaceExecState: number[] = [];
  // DIMENSION BUILT -- dimensionTotalBuilt
  private filterDimensionBuildExecState: QrFilterRange = new QrFilterRange();
  // DIMENSION LAND -- NO ESTA IMPLEMENTADO EN LA INTERFACE DE USUARIO
  private filterDimensionLandExecState: QrFilterRange = new QrFilterRange();
  // DIMENSION COVERED
  private filterDimensionCoveredExecState: QrFilterRange = new QrFilterRange();
  // APTO CREDITO
  private filterAptCreditExecState = false;
  // APTO PROFESIONAL
  private filterAptProfessionalUseExecState = false;
  // remaxCollection
  private filterRemaxCollectionExecState = false;
  // Opportunity
  private filterOpportunityExecState: number[] = [];
  // LandingPath
  private _landingPath: string = '';
  // GEO MULTI
  private filterGeoListExecState: GeoSearch[] = [];
  // POLYGONO COORDINATES
  private filterPolygonoExecState: string = '';
  // OPPORTUNITIES TYPES
  private filterOpportunitiesTypeExecState: number[] = [];
  // FILTER ORDER By
  private filterOrderByExecState: number[] = [];
  /* Cuenta la cantidad de filtros aplicados
   */
  private filterCountExecState: number = 0;
  // ------------------------------------
  private _viewMode: any = Constants.LISTINGS_VIEW_MODE_LIST;
  constructor(
    private listingAndEntrepreneurshipService: ListingAndEntrepreneurshipService,
    private mapService: MapService,
    private paramsDomain: ParamsDomain,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.setDefaultFilterExpense();
  }

  //-------------------------------------

  get pageSize(): number {
    return this.qrFilter.pageSize;
  }

  set pageSize(value: number) {
    if (value > 0) {
      this.qrFilter.pageSize = value;
      this.findAllListingAndEntrepreneurship();
    }
  }

  get page(): number {
    return this.qrFilter.page;
  }

  //
  set page(value: number) {
    if (value >= 0) {
      this.qrFilter.page = value;
      //	this.find();
    }
  }

  get totalItems(): number {
    return this._totalItem;
  }

  get totalPages(): number {
    return this._totalPages;
  }

  //-------------------------------------
  public async getQuery2String(): Promise<string> {
    if (this.isFilterChange() && this.qrFilter.page == this.pageExecState) {
      this.qrFilter.page = 0;
    }
    this._landingPath = '';
    await this.generateFilters();

    let value = this.qrFilter.toQueryStringFe();
    /* 
    if (this._officeName != null) {
      value += '&officeName=' + this._officeName;
    } */

    value += '&filterCount=' + this.filterCount;
    value += '&viewMode=' + this.viewMode;

    return value;
  }

  public async getQuery2StringEntre(): Promise<string> {
    if (
      this.isFilterChangeEntre() &&
      this.qrFilter.page == this.pageExecState
    ) {
      this.qrFilter.page = 0;
    }
    this._landingPath = '';
    this.filterOperation = [];
    await this.generateFilters();

    let value = this.qrFilter.toQueryStringFe();
    /* 
    if (this._officeName != null) {
      value += '&officeName=' + this._officeName;
    } */

    value += '&filterCount=' + this.filterCount;
    value += '&viewMode=' + this.viewMode;

    return value;
  }

  get listings(): Listing[] {
    return this._listings;
  }

  get entrepreneurships(): Entrepreneurship[] {
    return this._entrepreneurships;
  }

  get listingAndEntrepreneurship(): ListingAndEntrepreneurship[] {
    return this._listingAndEntrepreneurship;
  }

  get listingAndEntrepreneurshipMap(): Maps[] {
    return this._listingAndEntrepreneurshipMap;
  }

  //-------------------------------------
  get reportResult() {
    return this._reportResult;
  }

  // FILTROS STATE
  get filterOperation(): any[] {
    return this.filterOperation$.getValue();
  }

  set filterOperation(value: any[]) {
    this.filterOperation$.next(value);
  }

  get filterListingType(): number[] {
    return this.filterListingType$.getValue();
  }

  set filterListingType(value: number[]) {
    this.updateFilterState(this.filterListingType.length > 0, value.length > 0);
    this.filterListingType$.next(value);
  }

  //-------------------------------------
  get filterAreEntrepreneurshipsExcluded(): boolean {
    return this.filterAreEntrepreneurshipsExcluded$.getValue();
  }

  set filterAreEntrepreneurshipsExcluded(isExcluded: boolean) {
    this.updateFilterState(this.filterAreEntrepreneurshipsExcluded, isExcluded);
    this.filterAreEntrepreneurshipsExcluded$.next(isExcluded);
  }

  get filterCountResponse(): number | null {
    return this.filterCountResponse$.getValue();
  }

  set filterCountResponse(value: number | null) {
    this.filterCountResponseExecState = value;
    this.filterCountResponse$.next(value);
  }

  get officeName(): string {
    return this._officeName;
  }

  set officeName(value: string) {
    // if (value != null) {
    this._officeName = value;
    // }
  }

  get filterOfficeId(): string {
    return this.filterOfficeId$.getValue() || '';
  }

  set filterOfficeId(value: string) {
    this.filterOfficeId$.next(value);
  }

  get filterCondition(): number[] {
    return this.filterCondition$.getValue();
  }

  set filterCondition(value: number[]) {
    this.updateFilterState(this.filterCondition.length > 0, value.length > 0);
    this.filterCondition$.next(value);
  }

  get filterFeatures(): number[] {
    return this.filterFeatures$.getValue();
  }

  set filterFeatures(value: number[]) {
    this.updateFilterState(this.filterFeatures.length > 0, value.length > 0);
    this.filterFeatures$.next(value);
  }

  get filterPrice(): QrFilterPrice {
    return this.filterPrice$.getValue();
  }

  set filterPrice(value: QrFilterPrice) {
    this.updateFilterState(
      this.filterPrice.min > 0 || this.filterPrice.max > 0,
      value.min > 0 || value.max > 0
    );
    this.filterPrice$.next(value);
  }

  get filterExpense(): QrFilterPrice {
    return this.filterExpense$.getValue();
  }

  set filterExpense(value: QrFilterPrice) {
    this.updateFilterState(
      this.filterExpense.min > 0 || this.filterExpense.max > 0,
      value.min > 0 || value.max > 0
    );
    this.filterExpense$.next(value);
  }

  get filterYearBuild(): number[] {
    return this.filterYearBuild$.getValue();
  }

  set filterYearBuild(value: number[]) {
    this.updateFilterState(this.filterYearBuild.length > 0, value.length > 0);
    this.filterYearBuild$.next(value);
  }

  get filterRooms(): number[] {
    return this.filterRooms$.getValue();
  }

  set filterRooms(value: number[]) {
    this.updateFilterState(this.filterRooms.length > 0, value.length > 0);
    this.filterRooms$.next(value);
  }

  get filterBathrooms(): number[] {
    return this.filterBathrooms$.getValue();
  }

  set filterBathrooms(value: number[]) {
    this.updateFilterState(this.filterBathrooms.length > 0, value.length > 0);
    this.filterBathrooms$.next(value);
  }

  get filterBedrooms(): number[] {
    return this.filterBedrooms$.getValue();
  }

  set filterBedrooms(value: number[]) {
    this.updateFilterState(this.filterBedrooms.length > 0, value.length > 0);
    this.filterBedrooms$.next(value);
  }

  get filterDimensionBuilt(): QrFilterRange {
    return this.filterDimensionBuilt$.getValue();
  }

  set filterDimensionBuilt(value: QrFilterRange) {
    this.updateFilterState(
      this.filterDimensionBuilt.min > 0 || this.filterDimensionBuilt.max > 0,
      value.min > 0 || value.max > 0
    );
    this.filterDimensionBuilt$.next(value);
  }

  get filterDimensionLand(): QrFilterRange {
    return this.filterDimensionLand$.getValue();
  }

  set filterDimensionLand(value: QrFilterRange) {
    this.updateFilterState(
      this.filterDimensionLand.min > 0 || this.filterDimensionLand.max > 0,
      value.min > 0 || value.max > 0
    );
    this.filterDimensionLand$.next(value);
  }

  get filterDimensionCovered(): QrFilterRange {
    return this.filterDimensionCovered$.getValue();
  }

  set filterDimensionCovered(value: QrFilterRange) {
    this.updateFilterState(
      this.filterDimensionCovered.min > 0 ||
        this.filterDimensionCovered.max > 0,
      value.min > 0 || value.max > 0
    );
    this.filterDimensionCovered$.next(value);
  }

  get filterAptCredit(): boolean {
    return this.filterAptCredit$.getValue();
  }

  set filterAptCredit(value: boolean) {
    this.updateFilterState(this.filterAptCredit, value);
    this.filterAptCredit$.next(value);
  }

  get filterAptProfessionalUse(): boolean {
    return this.filterAptProfessionalUse$.getValue();
  }

  set filterAptProfessionalUse(value: boolean) {
    this.updateFilterState(this.filterAptProfessionalUse, value);
    this.filterAptProfessionalUse$.next(value);
  }

  get filterRemaxCollection(): boolean {
    return this.filterRemaxCollection$.getValue();
  }

  set filterRemaxCollection(value: boolean) {
    this.filterRemaxCollection$.next(value);
  }

  get filterOpportunity(): number[] {
    return this.filterOpportunity$.getValue();
  }

  set filterOpportunity(value: number[]) {
    this.updateFilterState(this.filterOpportunity.length > 0, value.length > 0);
    this.filterOpportunity$.next(value);
  }

  //-------------------------------------

  //-------------------------------------
  get filterGeo(): GeoSearch {
    if (this.filterGeoList != null && this.filterGeoList.length > 0) {
      return this.filterGeoList[0];
    } else {
      return new GeoSearch();
    }
  }

  // GEO
  set filterGeo(value: GeoSearch) {
    const check: GeoSearch = new GeoSearch();
    if (!check.isEqual(value)) {
      const list: GeoSearch[] = [];
      list.push(value);
      this.filterGeoList = list;
    } else {
      this.filterGeoList = [];
    }
  }

  //-------------------------------------
  get filterGeoList(): GeoSearch[] {
    return this.filterGeoList$.getValue();
  }
  //-------------------------------------

  set filterGeoList(value: GeoSearch[]) {
    if (value === null || value === undefined || value.length === 0) {
      this.updateFilterState(
        this.filterPoligono !== null && this.filterPoligono.length > 1,
        false
      );
      this.filterGeoList$.next([]);
    } else {
      this.filterPoligono = '';
      this.filterGeoList$.next([...value]);
    }
    this.updateFilterState(
      this.filterGeoList != null && this.filterGeoList.length > 0,
      value != null && value.length > 0
    );
  }

  get filterPoligono(): string {
    return this.filterPoligono$.getValue() || '';
  }

  set filterPoligono(value: string) {
    if (value !== null && value !== undefined && value.length > 0) {
      this.updateFilterState(
        this.filterGeo.label != null && this.filterGeo.label.length > 1,
        false
      );

      this.filterGeoList = [];
    }
    this.updateFilterState(
      this.filterPoligono !== null && this.filterPoligono.length > 1,
      value !== null && value.length > 1
    );
    this.filterPoligono$.next(value);
  }

  get filterOpportunitiesType(): number[] {
    return this.filterOpportunitiesType$.getValue();
  }

  set filterOpportunitiesType(value: number[]) {
    this.updateFilterState(
      this.filterOpportunitiesType.length > 0,
      value.length > 0
    );
    this.filterOpportunitiesType$.next(value);
  }

  //-------------------------------------
  get filterOrderBy(): number[] {
    return this.filterOrderBy$.getValue();
  }
  //-------------------------------------

  set filterOrderBy(value: number[]) {
    this.filterOrderBy$.next(value);
  }

  get filterStage(): number[] {
    return this.filterStage$.getValue();
  }

  set filterStage(value: number[]) {
    this.filterStage$.next(value);
  }

  get viewMode(): any {
    return this._viewMode;
  }

  set viewMode(value: string) {
    if (value != null) {
      this._viewMode = value;
    }
  }

  //-------------------------------------
  get filterCount(): number {
    return this.filterCount$.getValue();
  }

  set filterCount(value: number) {
    this.filterCount$.next(value);
  }

  get filterParkingSpace(): number[] {
    return this.filterParkingSpace$.getValue();
  }

  set filterParkingSpace(value: number[]) {
    this.updateFilterState(
      this.filterParkingSpace.length > 0,
      value.length > 0
    );
    this.filterParkingSpace$.next(value);
  }

  set landingPath(value: string) {
    this._landingPath = value;
  }

  /*  Resetea todo los filtros 
     sin tener en cuenta los resultados de busqueda
   */
  public resetAll(): void {
    this.filterOperation = [1];
    this.filterCondition = [];
    this.filterFeatures = [];
    this.filterListingType = [];
    this.filterStage = [0, 1, 2, 3, 4];
    this.filterGeoList = [];
    this.filterPoligono = '';
    this.filterPrice = new QrFilterPrice();
    this.filterExpense = new QrFilterPrice();
    this.setDefaultFilterExpense();
    this.filterYearBuild = [];
    this.filterRooms = [];
    this.filterBathrooms = [];
    this.filterBedrooms = [];
    this.filterParkingSpace = [];
    this.filterDimensionBuilt = new QrFilterRange();
    this.filterDimensionLand = new QrFilterRange();
    this.filterDimensionCovered = new QrFilterRange();
    this.filterOpportunitiesType = [];
    this.filterOrderBy = [];
    this.filterAptCredit = false;
    this.filterAptProfessionalUse = false;
    this.filterOfficeId = '';
    this.officeName = '';
    this._listings = [];
    this.listingLoading = false;
    this._entrepreneurships = [];
    this.entrepreneurshipLoading = false;
    this._listingAndEntrepreneurship = [];
    this.isResultsLoading = false;
    this._listingAndEntrepreneurshipMap = [];
    this.listingAndEntrepreneurshipMapLoading = false;
    this.qrFilter = new QrFilter(0, this.maxPerPage);
    this.filterCount = 0;
    this.page = 0;
    this.landingPath = '';
    this.filterAreEntrepreneurshipsExcluded = false;
  }

  /* Cuanto se cancela los filtros se vuelve
    al estado de busqueda actual
   */
  public cancelSelectedFilter(): void {
    this.filterOperation = [...this.filterOperationExecState];
    this.filterCondition = [...this.filterConditionExecState];
    this.filterFeatures = [...this.filterFeaturesExecState];
    this.filterListingType = [...this.filterListingTypeExecState];
    this.filterStage = [...this.filterStageExecState];
    this.filterAreEntrepreneurshipsExcluded =
      this.filterAreEntrepreneurshipsExcludedExecState;
    this.filterGeoList = [...this.filterGeoListExecState];
    this.filterPoligono = this.filterPolygonoExecState;
    this.filterPrice = this.filterPriceExecState.clone();
    this.filterExpense = this.filterExpenseExecState.clone();
    this.filterYearBuild = [...this.filterYearBuildExecState];
    this.filterRooms = [...this.filterRoomsExecState];
    this.filterBathrooms = [...this.filterBathroomsExecState];
    this.filterBedrooms = [...this.filterBedroomsExecState];
    this.filterParkingSpace = [...this.filterParkingSpaceExecState];
    this.filterDimensionBuilt = this.filterDimensionBuildExecState.clone();
    this.filterDimensionLand = this.filterDimensionLandExecState.clone();
    this.filterDimensionCovered = this.filterDimensionCoveredExecState.clone();
    this.filterOpportunitiesType = [...this.filterOpportunitiesTypeExecState];
    this.filterAptCredit = this.filterAptCreditExecState;
    this.filterAptProfessionalUse = this.filterAptProfessionalUseExecState;
    this.filterOrderBy = [...this.filterOrderByExecState];
    this.filterCount = this.filterCountExecState;
    this.filterOfficeId = this.privatefilterOfficeIdExecState;
  }

  //-------------------------------------
  public isFilterChange(): boolean {
    return (
      this.pageExecState != this.qrFilter.page ||
      this.pageSizeExecState != this.qrFilter.pageSize ||
      !arraysIsEqual(this.filterOperationExecState, this.filterOperation) ||
      !arraysIsEqual(this.filterConditionExecState, this.filterCondition) ||
      !arraysIsEqual(this.filterOrderByExecState, this.filterOrderBy) ||
      !arraysIsEqual(this.filterFeaturesExecState, this.filterFeatures) ||
      !arraysIsEqual(this.filterListingTypeExecState, this.filterListingType) ||
      !arraysIsEqual(this.filterGeoListExecState, this.filterGeoList) ||
      !this.filterPriceExecState.isEqual(this.filterPrice) ||
      !this.filterExpenseExecState.isEqual(this.filterExpense) ||
      !arraysIsEqual(this.filterYearBuildExecState, this.filterYearBuild) ||
      !arraysIsEqual(this.filterRoomsExecState, this.filterRooms) ||
      !arraysIsEqual(this.filterBathroomsExecState, this.filterBathrooms) ||
      !arraysIsEqual(this.filterBedroomsExecState, this.filterBedrooms) ||
      !arraysIsEqual(
        this.filterParkingSpaceExecState,
        this.filterParkingSpace
      ) ||
      !this.filterDimensionBuildExecState.isEqual(this.filterDimensionBuilt) ||
      !this.filterDimensionCoveredExecState.isEqual(
        this.filterDimensionCovered
      ) ||
      this.filterPolygonoExecState !== this.filterPoligono ||
      !arraysIsEqual(
        this.filterOpportunitiesTypeExecState,
        this.filterOpportunitiesType
      )
    );
  }

  //-------------------------------------
  public isFilterChangeEntre(): boolean {
    return (
      this.pageExecState != this.qrFilter.page ||
      this.pageSizeExecState != this.qrFilter.pageSize ||
      !arraysIsEqual(this.filterStageExecState, this.filterStage)
    );
  }

  //-------------------------------------
  public async windowLocationHref(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const queryParams = await this.getQuery2String();

      window.location.href =
        '/listings/' +
        (this.filterOperation.includes(1) ? 'buy?' : 'rent?') +
        queryParams;
    }
  }

  //-------------------------------------
  public async windowLocationHrefEmpre(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const queryParams = await this.getQuery2StringEntre();
      window.location.href = '/emprendimientos?' + queryParams;
    }
  }

  //-------------------------------------
  public async getURLLocation(publicationType: string): Promise<string> {
    if (isPlatformBrowser(this.platformId)) {
      let url = '';
      const BASE_URL = location.origin;
      if (publicationType == Constants.PUBLICATION_TYPE_LISTINGS) {
        const OPERATION_TYPE_SECTION = this.filterOperation.includes(1)
          ? 'buy?'
          : 'rent?';
        url =
          BASE_URL +
          '/' +
          publicationType +
          '/' +
          OPERATION_TYPE_SECTION +
          (await this.getQuery2String());
      } else if (
        publicationType == Constants.PUBLICATION_TYPE_ENTREPRENEURSHIP
      ) {
        url =
          BASE_URL +
          '/' +
          publicationType +
          '?' +
          (await this.getQuery2StringEntre());
      }
      return url;
    }
    return '';
  }

  //-------------------------------------
  public async newApiFind(checkChange = true): Promise<void> {
    if (checkChange) {
      if (this.isFilterChange()) {
        this.qrFilter = new QrFilter(0, this.maxPerPage);
        this.findAllListingAndEntrepreneurship();
      }
    } else {
      this.findAllListingAndEntrepreneurship();
    }
  }

  //   NEW API FIND ENTREPRENEURSHIP
  public async newApiFindEntrepreneurship(checkChange = true): Promise<void> {
    if (checkChange) {
      if (this.isFilterChangeEntre()) {
        this.qrFilter = new QrFilter(0, this.maxPerPage);
        this.findAllEntrepreneurship(true);
      }
    } else {
      this.findAllEntrepreneurship(true);
    }
  }

  //-------------------------------------
  public loadState2QrPage(qrPage: IQrPage): void {
    this._totalItem = qrPage.totalItems;
    this._totalPages = qrPage.totalPages;
    this._listings = qrPage.data;
    this.updateFiltersExcuteState();
    this.setReportResult();
    this.listingLoading = false;
  }

  //-------------------------------------
  public loadState2QrPageListingAndEntrepreneurship(qrPage: IQrPage): void {
    this._totalItem = qrPage.totalItems;
    this._totalPages = qrPage.totalPages;
    this._listingAndEntrepreneurship = qrPage.data;
    this.updateFiltersExcuteState();
    this.setReportResult();
    this.isResultsLoading = false;
  }

  //-------------------------------------
  public updateStateMap(qrPage: IQrPage): void {
    this._totalItem = qrPage.totalItems;
    this._totalPages = qrPage.totalPages;
    this._listingAndEntrepreneurshipMap = qrPage.data;
    this.updateFiltersExcuteState();
    this.setReportResult();
    this.listingAndEntrepreneurshipMapLoading = false;
  }

  public async findAllListingAndEntrepreneurship(
    isEntrepreneurship?: boolean
  ): Promise<void> {
    this.isResultsLoading = true;

    // Espera a que generateFilters termine antes de continuar
    await this.generateFilters();

    const QUERY: string =
      this.qrFilter.toQueryStringBe() +
      (isEntrepreneurship !== undefined
        ? `&eq=entrepreneurship:${isEntrepreneurship}`
        : '');

    try {
      const response: any = await this.listingAndEntrepreneurshipService
        .getParams<string, IQrResponse<IQrPage>>(QUERY)
        .toPromise();

      if (response.code === 200) {
        this._listingAndEntrepreneurship = Object.assign(
          [],
          response.data.data
        );
        const qrPage: IQrPage = response.data;
        this.loadState2QrPageListingAndEntrepreneurship(qrPage);
      } else {
        console.warn(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isResultsLoading = false;
    }
  }

  public async findAllEntrepreneurship(
    isEntrepreneurship?: boolean
  ): Promise<void> {
    this.isResultsLoading = true;

    // Espera a que generateFilters termine antes de continuar
    await this.generateFilters();

    const QUERY: string =
      this.qrFilter.toQueryStringBe() +
      (isEntrepreneurship !== undefined
        ? `&eq=entrepreneurship:${isEntrepreneurship}`
        : '');

    try {
      const response: any = await this.listingAndEntrepreneurshipService
        .getParams<string, IQrResponse<IQrPage>>(QUERY)
        .toPromise();

      if (response.code === 200) {
        this._entrepreneurships = Object.assign([], response.data.data);
        const PAGE: IQrPage = response.data;
        this.loadState2QrPageListingAndEntrepreneurship(PAGE);
      } else {
        console.warn(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isResultsLoading = false;
    }
  }

  public async searchResultMap(isEntrepreneurship?: boolean): Promise<void> {
    this.listingAndEntrepreneurshipMapLoading = true;

    // Espera a que generateFilters termine antes de continuar
    await this.generateFilters();

    let QUERY: string =
      this.qrFilter.toQueryStringBe() +
      (isEntrepreneurship !== undefined
        ? `&eq=entrepreneurship:${isEntrepreneurship}`
        : '');

    QUERY = QUERY.replace(/page=\d+/, 'page=0');

    try {
      const response: any = await this.mapService
        .getParams<string, IQrResponse<IQrPage>>(QUERY)
        .toPromise();

      if (response.code === 200) {
        this._listingAndEntrepreneurshipMap = Object.assign(
          [],
          response.data.data
        );
        const qrPage: IQrPage = response.data;
        this.updateStateMap(qrPage);
      } else {
        console.warn(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.listingAndEntrepreneurshipMapLoading = false;
    }
  }

  //-------------------------------------
  public async generateFilters() {
    this.qrFilter.ressetFilters();

    // FILTRO ORDER BY
    if (this.filterOrderBy !== undefined && this.filterOrderBy.length > 0) {
      if (this.filterOrderBy[0] == 1) {
        this.qrFilter.sorts = ['+priceUsd'];
      } else {
        this.qrFilter.sorts = ['-priceUsd'];
      }
    }

    //FILTRO OPERATION sale,rent, temporal
    if (
      this.filterOperation !== undefined &&
      this.filterOperation !== null &&
      this.filterOperation.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.in,
        'operationId',
        this.filterOperation.toString()
      );
      this.qrFilter.addFilter(filter);
    }

    //FILTRO STAGE
    if (
      this.filterStage !== undefined &&
      this.filterStage !== null &&
      this.filterStage.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.in,
        'eStageId',
        this.filterStage.toString()
      );
      this.qrFilter.addFilter(filter);
    }

    // Filtro: ¿Excluir emprendimientos en los resultados?
    if (
      this.filterAreEntrepreneurshipsExcluded !== undefined &&
      this.filterAreEntrepreneurshipsExcluded !== null
    ) {
      if (this.filterAreEntrepreneurshipsExcluded) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.eq,
          'entrepreneurship',
          false
        ); // eq:entrepreneurship:false --> solo muestra propiedades, excluye a los emprendimientos de los resultados.
        this.qrFilter.addFilter(filter);
      }
    }

    //FILTRO TYPE casa,quinta,local...
    if (
      this.filterListingType !== undefined &&
      this.filterListingType.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.in,
        'typeId',
        this.filterListingType.toString()
      );
      this.qrFilter.addFilter(filter);
    }

    //FILTRO CONDITION casa,quinta,local...
    if (this.filterCondition !== undefined && this.filterCondition.length > 0) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.conditionsin,
        null,
        this.filterCondition.toString()
      );
      this.qrFilter.addFilter(filter);
    }
    //FILTRO FEATURES casa,quinta,local...
    if (this.filterFeatures !== undefined && this.filterFeatures.length > 0) {
      this.filterFeatures = this.filterFeatures.filter(
        e => e !== 67 && e !== 68
      );
      if (this.filterFeatures.length > 0) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.featuresin,
          null,
          this.filterFeatures.toString()
        );
        this.qrFilter.addFilter(filter);
      }
    }
    //FILTRO YEARBUILD
    if (this.filterYearBuild !== undefined && this.filterYearBuild.length > 0) {
      const itemId: number = this.filterYearBuild[0];
      if (itemId === 1) {
        //ItemParam(1,'upTo5years'),
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'yearBuilt',
          5
        );
        this.qrFilter.addFilter(filter);
      } else if (itemId === 2) {
        //     new ItemParam(2,'between5and10years'),
        const filter1: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'yearBuilt',
          5
        );
        this.qrFilter.addFilter(filter1);

        const filter2: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'yearBuilt',
          10
        );
        this.qrFilter.addFilter(filter2);
      } else if (itemId === 3) {
        //  new ItemParam(3,'between10and20years'),
        const filter1: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'yearBuilt',
          10
        );
        this.qrFilter.addFilter(filter1);

        const filter2: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'yearBuilt',
          20
        );
        this.qrFilter.addFilter(filter2);
      } else if (itemId === 4) {
        //  new ItemParam(4,'between20and50years'),
        const filter1: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'yearBuilt',
          20
        );
        this.qrFilter.addFilter(filter1);

        const filter2: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'yearBuilt',
          50
        );
        this.qrFilter.addFilter(filter2);
      } else if (itemId === 5) {
        //   new ItemParam(5,'moreThan50years'),
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'yearBuilt',
          50
        );
        this.qrFilter.addFilter(filter);
      }
    }

    //FILTRO PRICE
    if (this.filterPrice !== undefined && this.filterPrice !== null) {
      //let addCurrencyFilter = false;

      if (this.filterPrice.min > 0 || this.filterPrice.max > 0) {
        const priceMin =
          this.filterPrice.min !== null && this.filterPrice.min > 0
            ? this.filterPrice.min
            : 0;
        const priceMax =
          this.filterPrice.max !== null && this.filterPrice.max > 0
            ? this.filterPrice.max
            : 0;

        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.pricein,
          this.filterPrice.currencyId.toString(),
          priceMin + ':' + priceMax
        );
        this.qrFilter.addFilter(filter);
      }
    }

    //FILTRO EXPENSAS
    if (this.filterExpense !== undefined && this.filterExpense !== null) {
      let addCurrencyFilter = false;

      if (
        this.filterExpense.min !== undefined &&
        this.filterExpense.min !== null &&
        this.filterExpense.min > 0
      ) {
        addCurrencyFilter = true;
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'expensesPrice',
          this.filterExpense.min
        );
        this.qrFilter.addFilter(filter);
      }

      if (
        this.filterExpense.max !== undefined &&
        this.filterExpense.max !== null &&
        this.filterExpense.max > 0
      ) {
        addCurrencyFilter = true;
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'expensesPrice',
          this.filterExpense.max
        );
        this.qrFilter.addFilter(filter);
      }

      if (addCurrencyFilter) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.eq,
          'expensesCurrencyId',
          this.filterExpense.currencyId
        );
        this.qrFilter.addFilter(filter);
      }
    }
    //FILTRO ROOMS
    if (this.filterRooms !== undefined && this.filterRooms !== null) {
      // new ItemParam(1,'1',), uso el id como el valor para buscar la cantidad
      // si el id==lastId buscar por mayor o igual, sino busca por in
      try {
        if (this.filterRooms.length == 1) {
          const roomsType: ItemParam[] =
            await this.paramsDomain.getRoomsTypes();
          const lastId = roomsType[roomsType.length - 1].id;
          const itemId: number = this.filterRooms[0];
          const filter: QrFilterCustom = new QrFilterCustom(
            lastId == itemId ? IQrFilterType.gte : IQrFilterType.eq,
            'totalRooms',
            itemId
          );
          this.qrFilter.addFilter(filter);
        } else if (this.filterRooms.length > 1) {
          const filter: QrFilterCustom = new QrFilterCustom(
            IQrFilterType.in,
            'totalRooms',
            this.filterRooms.toString()
          );
          this.qrFilter.addFilter(filter);
        }
      } catch (error) {
        console.error(error);
      }
    }

    //FILTRO BATHROOMS
    if (this.filterBathrooms !== undefined && this.filterBathrooms !== null) {
      // new ItemParam(1,'1',), uso el id como el valor para buscar la cantidad
      // si el id==lastId buscar por mayor o igual, sino busca por in
      if (this.filterBathrooms.length == 1) {
        const bathroomsType = await this.paramsDomain.getBathRoomsTypes();
        const lastId = bathroomsType[bathroomsType.length - 1].id;
        const itemId: number = this.filterBathrooms[0];

        const filter: QrFilterCustom = new QrFilterCustom(
          lastId == itemId ? IQrFilterType.gte : IQrFilterType.eq,
          'bathrooms',
          itemId
        );
        this.qrFilter.addFilter(filter);
      } else if (this.filterBathrooms.length > 1) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.in,
          'bathrooms',
          this.filterBathrooms.toString()
        );
        this.qrFilter.addFilter(filter);
      }
    }
    //FILTRO BEDROOMS
    if (this.filterBedrooms !== undefined && this.filterBedrooms !== null) {
      // new ItemParam(1,'1',), uso el id como el valor para buscar la cantidad
      // si el id==lastId buscar por mayor o igual, sino busca por in
      if (this.filterBedrooms.length == 1) {
        const bedroomsType = await this.paramsDomain.getBedroomsTypes();
        const lastId = bedroomsType[bedroomsType.length - 1].id;
        const itemId: number = this.filterBedrooms[0];

        const filter: QrFilterCustom = new QrFilterCustom(
          lastId == itemId ? IQrFilterType.gte : IQrFilterType.eq,
          'bedrooms',
          itemId
        );
        this.qrFilter.addFilter(filter);
      } else if (this.filterBedrooms.length > 1) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.in,
          'bedrooms',
          this.filterBedrooms.toString()
        );
        this.qrFilter.addFilter(filter);
      }
    }

    //FILTRO PARKINGS SPACE
    if (
      this.filterParkingSpace !== undefined &&
      this.filterParkingSpace !== null
    ) {
      // new ItemParam(1,'1',), uso el id como el valor para buscar la cantidad
      // si el id==lastId buscar por mayor o igual, sino busca por in
      if (this.filterParkingSpace.length == 1) {
        const parkingSpacesType =
          await this.paramsDomain.getParkingSpacesTypes();
        const lastId = parkingSpacesType[parkingSpacesType.length - 1].id;
        const itemId: number = this.filterParkingSpace[0];

        const filter: QrFilterCustom = new QrFilterCustom(
          lastId == itemId ? IQrFilterType.gte : IQrFilterType.eq,
          'parkingSpaces',
          itemId
        );
        this.qrFilter.addFilter(filter);
      } else if (this.filterParkingSpace.length > 1) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.in,
          'parkingSpaces',
          this.filterParkingSpace.toString()
        );
        this.qrFilter.addFilter(filter);
      }
    }
    //FILTRO DIMENSION ALDN
    if (
      this.filterDimensionLand !== undefined &&
      this.filterDimensionLand !== null
    ) {
      if (
        this.filterDimensionLand.min !== undefined &&
        this.filterDimensionLand.min !== null &&
        this.filterDimensionLand.min > 0
      ) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'dimensionLand',
          this.filterDimensionLand.min
        );
        this.qrFilter.addFilter(filter);
      }

      if (
        this.filterDimensionLand.max !== undefined &&
        this.filterDimensionLand.max !== null &&
        this.filterDimensionLand.max > 0
      ) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'dimensionLand',
          this.filterDimensionLand.max
        );
        this.qrFilter.addFilter(filter);
      }
    }

    //FILTRO DIMENSION BUILD
    if (
      this.filterDimensionBuilt !== undefined &&
      this.filterDimensionBuilt !== null
    ) {
      if (
        this.filterDimensionBuilt.min !== undefined &&
        this.filterDimensionBuilt.min !== null &&
        this.filterDimensionBuilt.min > 0
      ) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'dimensionTotalBuilt',
          this.filterDimensionBuilt.min
        );
        this.qrFilter.addFilter(filter);
      }

      if (
        this.filterDimensionBuilt.max !== undefined &&
        this.filterDimensionBuilt.max !== null &&
        this.filterDimensionBuilt.max > 0
      ) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'dimensionTotalBuilt',
          this.filterDimensionBuilt.max
        );
        this.qrFilter.addFilter(filter);
      }
    }
    //FILTRO DIMENSION COVERED
    if (
      this.filterDimensionCovered !== undefined &&
      this.filterDimensionCovered !== null
    ) {
      if (
        this.filterDimensionCovered.min !== undefined &&
        this.filterDimensionCovered.min !== null &&
        this.filterDimensionCovered.min > 0
      ) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.gte,
          'dimensionCovered',
          this.filterDimensionCovered.min
        );
        this.qrFilter.addFilter(filter);
      }

      if (
        this.filterDimensionCovered.max !== undefined &&
        this.filterDimensionCovered.max !== null &&
        this.filterDimensionCovered.max > 0
      ) {
        const filter: QrFilterCustom = new QrFilterCustom(
          IQrFilterType.lte,
          'dimensionCovered',
          this.filterDimensionCovered.max
        );
        this.qrFilter.addFilter(filter);
      }
    }

    // FILTRO APTO CREDITO
    if (this.filterAptCredit) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        'aptCredit',
        true
      );
      this.qrFilter.addFilter(filter);
    }

    // FILTRO APTO PROFESIONAL
    if (this.filterAptProfessionalUse) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        'professionalUse',
        true
      );
      this.qrFilter.addFilter(filter);
    }

    // FILTRO remaxCollection
    if (
      this.filterRemaxCollection != null &&
      this.filterRemaxCollection == true
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        'remaxCollection',
        true
      );
      this.qrFilter.addFilter(filter);
    }

    // FILTRO opportunity
    if (
      Array.isArray(this.filterOpportunity) &&
      this.filterOpportunity.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.in,
        'opportunity',
        this.filterOpportunity.toString
      );
      this.qrFilter.addFilter(filter);
    }
    // FILTRO OfficeId office.internal_id
    if (
      this.filterOfficeId !== null &&
      this.filterOfficeId !== undefined &&
      this.filterOfficeId.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        IQrFilterType.officeId,
        this.filterOfficeId
      );
      this.qrFilter.addFilter(filter);
    }
    if (
      this.officeName !== null &&
      this.officeName !== undefined &&
      this.officeName.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.officeName,
        null,
        this.officeName
      );
      this.qrFilter.addFilter(filter);
    }
    // FILTRO GEO WITH AREA
    if (this.filterPoligono !== null && this.filterPoligono.length > 0) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.withinarea,
        'location',
        this.filterPoligono
      );
      this.qrFilter.addFilter(filter);
    }
    ///////////////////////////////////////////////////////////
    // FILTRO MULTIPLE GEO
    if (this.filterGeoList != null && this.filterGeoList.length) {
      const values: string = GeoSearch.encodeToFilter(this.filterGeoList);
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.locations,
        IQrFilterType.in,
        values
      );
      this.qrFilter.addFilter(filter);
    }
    ///////////////////////////////////////////////////////////
    // FILTRO OPPORTUNITY TYPES
    if (
      this.filterOpportunitiesType !== undefined &&
      this.filterOpportunitiesType.length > 0
    ) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.in,
        'opportunityId',
        this.filterOpportunitiesType.toString()
      );
      this.qrFilter.addFilter(filter);
    }

    ///////////////////////////////////////////////////////////
    // FILTRO LANDINGPATH
    if (this._landingPath != null && this._landingPath != undefined) {
      const filter: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.landingPath,
        null,
        this._landingPath
      );
      this.qrFilter.addFilter(filter);
    }
  }

  // DECODIFICA FILTRO DE URL
  queryParams2filter(params: any) {
    if (params == null || params == undefined) {
      return;
    }
    this.resetAll();

    for (const key of Object.keys(params)) {
      let keyAux = key;
      if (key.includes(':')) {
        keyAux = key.split(':')[0];
      }

      switch (keyAux) {
        case 'page':
          this.qrFilter.page = +params[key];
          break;
        case 'pageSize':
          this.qrFilter.pageSize = +params[key];
          break;
        case 'sort':
          if (params[key] == '+priceUsd') {
            this.filterOrderBy = [1];
          } else if (params[key] == '-priceUsd') {
            this.filterOrderBy = [2];
          }
          break;
        case 'gte':
          this.setGteValueParam(key.split(':')[1], params[key]);
          break;
        case 'lte':
          this.setLteValueParam(key.split(':')[1], params[key]);
          break;
        case 'eq':
          this.setEqValueParam(key.split(':')[1], params[key]);
          break;
        case 'in':
          this.setInValueParam(key.split(':')[1], params[key]);
          break;
        case 'conditionsin':
          this.filterCondition = params[key].split(',').map(function (i: any) {
            return parseInt(i, 10);
          });
          break;
        case 'featuresin':
          this.filterFeatures = params[key].split(',').map(function (i: any) {
            return parseInt(i, 10);
          });
          break;
        case 'filterCount':
          this.filterCount = 0;
          if (params[key]) this.filterCount = Number(params[key]);
          break;
        case 'viewMode':
          this.viewMode = params[key] ?? Constants.LISTINGS_VIEW_MODE_GRID;
          break;

        case 'withinarea':
          const polygono = params[key].split(':')[1];
          if (polygono != null) {
            this.filterPoligono = polygono;
          }
          break;

        case 'officeId':
          const officeid = params[key];
          if (officeid != null) {
            this.filterOfficeId = officeid;
          }
          break;

        case 'officeName':
          const officeName = params[key];
          if (officeName != null) {
            this.officeName = officeName;
          }
          break;
        case 'locations':
          const values = GeoSearch.decodeToFilter(params[key]);
          if (values != null) {
            this.filterGeoList = values;
          }
          break;
        case 'pricein':
          const prices = params[key].split(':');

          this.filterPrice.currencyId =
            prices[0] !== null && +prices[0] > 0 ? +prices[0] : null;
          this.filterPrice.min =
            prices[1] !== null && +prices[1] > 0 ? +prices[1] : null;
          this.filterPrice.max =
            prices[2] !== null && +prices[2] > 0 ? +prices[2] : null;

          break;
        case 'landingPath':
          this._landingPath = params[key];
          break;
      }
    }
  }

  private set listingLoading(value: boolean) {
    this.listingsLoading$.next(value);
  }

  private set entrepreneurshipLoading(value: boolean) {
    this.entrepreneurshipLoading$.next(value);
  }

  private set isResultsLoading(value: boolean) {
    this.isResultsLoading$.next(value);
  }

  private set listingAndEntrepreneurshipMapLoading(value: boolean) {
    this.listingAndEntrepreneurshipMapLoading$.next(value);
  }

  private set isOnlyEntrepreneurshipResults(value: boolean) {
    this.isOnlyEntrepreneurshipResults$.next(value);
  }

  private set isReportResultLoading(value: boolean) {
    this.isReportResultLoading$.next(value);
  }

  private async setReportResult() {
    if (!this.checkedTitle) {
      if (isPlatformBrowser(this.platformId)) {
        this.isReportResultLoading = true;
        const urlIncludesEntrepreneurships =
          window.location.href.includes('/emprendimientos');

        // Si todos los elementos en el array original son emprendimientos, entonces isEntrepreneurship es true
        this.isEntrepreneurship = urlIncludesEntrepreneurships;
        this.isOnlyEntrepreneurshipResults = urlIncludesEntrepreneurships;
      }

      this._reportResult.quantity = this.totalItems;
      if (this.filterOpportunitiesTypeExecState.length > 0) {
        this._reportResult.opportunity = true;
      }

      // ESTOS STRINGS SOLO AFECTAN AL INNERHTML DEL RESULTADO DE BÚSQUEDA (TitleText)
      //🌎 Argentina
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_DUPLEX] =
        'departamento duplex';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_ESTANDAR] =
        'departamento estandar';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_LOFT] =
        'departamento loft';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_MONOAMBIENTE] =
        'departamento monoambiente';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_PENTHOUSE] =
        'departamento penthouse';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_PISO] =
        'departamento piso';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_SEMIPISO] =
        'departamento semipiso';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_TRIPLEX] =
        'departamento triplex';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTOS] = 'departamentos';
      this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO] = 'departamentos';
      this.dataArray[Constants.PROPERTY_TYPE_PH] = 'ph';
      this.dataArray[Constants.PROPERTY_TYPE_HOTEL] = 'hotel';
      this.dataArray[Constants.PROPERTY_TYPE_EDIFICIO] = 'edificio';
      this.dataArray[Constants.PROPERTY_TYPE_OTROS_INMUEBLES] =
        'otros inmuebles';
      this.dataArray[Constants.PROPERTY_TYPE_OFICINA] = 'oficinas';
      this.dataArray[Constants.PROPERTY_TYPE_LOCAL] = 'locales';
      this.dataArray[Constants.PROPERTY_TYPE_TERRENOS_Y_LOTES] =
        'terrenos y lotes';
      this.dataArray[Constants.PROPERTY_TYPE_CAMPO] = 'campo';
      this.dataArray[Constants.PROPERTY_TYPE_FONDO_DE_COMERCIO] =
        'fondo de comercio';
      this.dataArray[Constants.PROPERTY_TYPE_COCHERA] = 'cochera';
      this.dataArray[Constants.PROPERTY_TYPE_GALPON] = 'galpon';
      this.dataArray[Constants.PROPERTY_TYPE_CASA] = 'casa';
      this.dataArray[Constants.PROPERTY_TYPE_CASA_DUPLEX] = 'casa duplex';
      this.dataArray[Constants.PROPERTY_TYPE_CASA_TRIPLEX] = 'casa triplex';
      this.dataArray[Constants.PROPERTY_TYPE_QUINTA] = 'quinta';
      this.dataArray[Constants.PROPERTY_TYPE_CONSULTORIO] = 'consultorio';
      this.dataArray[Constants.PROPERTY_TYPE_CHACRA] = 'chacra';
      this.dataArray[Constants.PROPERTY_TYPE_DEPOSITO] = 'deposito';
      this.dataArray[Constants.PROPERTY_TYPE_CASAS] = 'casas';
      //🌎 Uruguay
      if (environment.node == 'uy') {
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_DUPLEX] =
          'apartamento duplex';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_ESTANDAR] =
          'apartamento estandar';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_LOFT] =
          'apartamento loft';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_MONOAMBIENTE] =
          'apartamento monoambiente';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_PENTHOUSE] =
          'apartamento penthouse';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_PISO] =
          'apartamento piso';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_SEMIPISO] =
          'apartamento semipiso';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTO_TRIPLEX] =
          'apartamento triplex';
        this.dataArray[Constants.PROPERTY_TYPE_DEPARTAMENTOS] = 'apartamentos';
        //🌎 Ecuador
      } else if (environment.node == 'ec') {
        this.dataArray[Constants.PROPERTY_TYPE_EC_TERRENO] = 'terrenos';
        this.dataArray[Constants.PROPERTY_TYPE_EC_QUINTA] = 'quintas';
        this.dataArray[Constants.PROPERTY_TYPE_EC_HOTEL] = 'hoteles';
        this.dataArray[Constants.PROPERTY_TYPE_EC_OFICINA] = 'oficinas';
        this.dataArray[Constants.PROPERTY_TYPE_EC_GALPON] = 'galpones';
        this.dataArray[Constants.PROPERTY_TYPE_EC_EDIFICIO_COMPLETO] =
          'edificios completos';
        this.dataArray[Constants.PROPERTY_TYPE_EC_PROPIEDAD_AGRICOLA] =
          'propiedades agrícolas';
        this.dataArray[Constants.PROPERTY_TYPE_EC_OTRO] = 'otros';
        this.dataArray[Constants.PROPERTY_TYPE_EC_VENTA_NEGOCIO] =
          'venta de negocios';
        this.dataArray[Constants.PROPERTY_TYPE_EC_PARQUEO] = 'garages/parqueos';
        this.dataArray[Constants.PROPERTY_TYPE_EC_BAULERA] = 'bauleras';
        this.dataArray[Constants.PROPERTY_TYPE_EC_PENTHOUSE] = 'penthouse';
        this.dataArray[Constants.PROPERTY_TYPE_EC_BANANERA] = 'bananeras';
        this.dataArray[Constants.PROPERTY_TYPE_EC_CAMARONERA] = 'camaroneras';
        this.dataArray[Constants.PROPERTY_TYPE_EC_LOCAL_COMERCIAL] =
          'locales comerciales';
        this.dataArray[Constants.PROPERTY_TYPE_EC_GASOLINERA] = 'gasolineras';
        this.dataArray[Constants.PROPERTY_TYPE_EC_MINA] = 'minas';
        this.dataArray[Constants.PROPERTY_TYPE_EC_TERRENO_COMERCIAL] =
          'terrenos comerciales';
        this.dataArray[Constants.PROPERTY_TYPE_EC_CONSULTORIO] = 'consultorios';
        this.dataArray[Constants.PROPERTY_TYPE_EC_INVERSION] = 'invesiones';
        this.dataArray[Constants.PROPERTY_TYPE_EC_CENTRO_COMERCIAL] =
          'centro comerciales';
        this.dataArray[Constants.PROPERTY_TYPE_EC_GIMNASIO] = 'gimnasio';
        this.dataArray[Constants.PROPERTY_TYPE_EC_EDIFICIO_COMERCIAL] =
          'edificio comercial';
        this.dataArray[Constants.PROPERTY_TYPE_EC_HOUSE_COMERCIAL] =
          'casa comercial';
      }

      const deptos = [
        'departamento_ph',
        'departamento_triplex',
        'departamento_semipiso',
        'departamento_piso',
        'departamento_penthouse',
        'departamento_monoambiente',
        'departamento_loft',
        'departamento_estandar',
        'departamento_duplex',
        'departamento',
      ];

      const houses = ['casa_duplex', 'casa_triplex', 'casa'];

      const listingType: ItemParam[] =
        await this.paramsDomain.getListingsTypes();
      let aptCount = 0;
      let houseCount = 0;

      this.filterListingType.forEach(id => {
        const index: number = listingType.findIndex(i => i.id == id);
        if (deptos.includes(listingType[index].value)) {
          aptCount++;
        }
        if (houses.includes(listingType[index].value)) {
          houseCount++;
        }
        this._reportResult.propertyType.push(listingType[index].value);
      });
      if (aptCount > 1) {
        const length = this._reportResult.propertyType.length;
        for (let index = 0; index < length; index++) {
          if (deptos.includes(this._reportResult.propertyType[index])) {
            this._reportResult.propertyType.splice(index, 1);
            index--;
          }
        }
        this._reportResult.propertyType.unshift('departamentos');
      }
      if (houseCount > 1) {
        const length = this._reportResult.propertyType.length;
        for (let index = 0; index < length; index++) {
          if (houses.includes(this._reportResult.propertyType[index])) {
            this._reportResult.propertyType.splice(index, 1);
            index--;
          }
        }
        this._reportResult.propertyType.unshift('casas');
      }

      const indexTerrenos: number = listingType.findIndex(
        i => i.value == Constants.PROPERTY_TYPE_TERRENOS_Y_LOTES
      );

      if (
        this.filterListingType.includes(
          Constants.PROPERTY_TYPE_ID_TERRENOS_Y_LOTES
        )
      ) {
        this._reportResult.propertyType.push(
          this._reportResult.propertyType.splice(indexTerrenos, 1)[0]
        );
      }

      if (this.filterOrderBy.length > 0) {
        const filtersTypes = await this.paramsDomain.getOrdersBy();
        this.filterOrderBy.forEach(e => {
          const index: number = filtersTypes.findIndex(i => i.id == e);
          this._reportResult.orderBy.push(filtersTypes[index].value);
        });
      }

      // Emprendimientos stage operation
      const stageTypes: string[] = [];
      stageTypes[0] = ''; // Se agrego el tipo 0 para que vengan los resultados de propiedades
      stageTypes[3] = 'pozo';
      stageTypes[1] = 'pre venta';
      stageTypes[4] = 'terminado';
      stageTypes[2] = 'construcción';

      this.filterStage.forEach(id => {
        this._reportResult.stage.push(stageTypes[id]);
      });

      let stringprop = ' propiedades';
      if (this.isEntrepreneurship) {
        stringprop = ' con';
      }

      if (
        this._reportResult.propertyType &&
        this._reportResult.propertyType.length > 0
      ) {
        stringprop = '';
        for (
          let index = 0;
          index < this._reportResult.propertyType.length;
          index++
        ) {
          if (index == 0) {
            stringprop +=
              ' ' + this.dataArray[this._reportResult.propertyType[index]];
          } else if (index == this._reportResult.propertyType.length - 1) {
            if (
              this._reportResult.propertyType[index] ==
              Constants.PROPERTY_TYPE_TERRENOS_Y_LOTES
            ) {
              stringprop +=
                ', ' + this.dataArray[this._reportResult.propertyType[index]];
            } else {
              stringprop +=
                ' y ' + this.dataArray[this._reportResult.propertyType[index]];
            }
          } else {
            stringprop +=
              ', ' + this.dataArray[this._reportResult.propertyType[index]];
          }
        }
        this._reportResult.titleText =
          this._reportResult.titleText.concat(stringprop);
      } else {
        let string = '';
        if (!this.isEntrepreneurship) {
          string = ' propiedades';
        }
        this._reportResult.titleText =
          this._reportResult.titleText.concat(string);
      }

      if (!this.isEntrepreneurship) {
        const optypes: any = [];
        optypes['sale'] = 'venta';
        optypes['rent'] = 'alquiler';
        optypes['temporal'] = 'alquiler temporal';

        const operationType: ItemParam[] =
          await this.paramsDomain.getOperationsTypes();
        this.operationTypes = operationType;
        this.filterOperation.forEach(id => {
          const index: number = operationType.findIndex(i => i.id == id);
          this._reportResult.operationType.push(operationType[index].value);
        });
      }

      this._reportResult.operationType = this.filterDuplicates(
        this._reportResult.operationType
      );
      this._reportResult.propertyType = this.filterDuplicates(
        this._reportResult.propertyType
      );
      this._reportResult.stage = this.filterDuplicates(
        this._reportResult.stage
      );
      this._reportResult.titleText = this.checkTitleText(this._reportResult);
    }
    this.isReportResultLoading = false;
  }

  //filtrado de duplicados en listas del reporte de resultado
  private filterDuplicates<T>(array: T[]): T[] {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  }

  //genera el texto que describe la búsqueda realizada
  private checkTitleText(reportResult: any): string {
    let titleText: string = '';
    titleText =
      '<strong>' + reportResult.quantity.toLocaleString() + '</strong>';

    if (this.isEntrepreneurship) {
      let stringStage = ' emprendimientos';
      for (let index = 0; index < reportResult.operationType.length; index++) {
        if (index == 0) {
          stringStage += ' en ' + reportResult.operationType[index];
        } else if (index == reportResult.operationType.length - 1) {
          stringStage += ' y ' + reportResult.operationType[index];
        } else {
          stringStage += ', ' + reportResult.operationType[index];
        }
      }
      titleText = titleText.concat(stringStage);
    }

    let stringprop = ' propiedades';
    if (this.isEntrepreneurship) {
      stringprop = ' con';
    }

    if (reportResult.propertyType && reportResult.propertyType.length > 0) {
      stringprop = '';
      for (let index = 0; index < reportResult.propertyType.length; index++) {
        if (index == 0) {
          stringprop += ' ' + this.dataArray[reportResult.propertyType[index]];
        } else if (index == reportResult.propertyType.length - 1) {
          if (
            reportResult.propertyType[index] ==
            Constants.PROPERTY_TYPE_TERRENOS_Y_LOTES
          ) {
            stringprop +=
              ', ' + this.dataArray[reportResult.propertyType[index]];
          } else {
            stringprop +=
              ' y ' + this.dataArray[reportResult.propertyType[index]];
          }
        } else {
          stringprop += ', ' + this.dataArray[reportResult.propertyType[index]];
        }
      }
      titleText = titleText.concat(stringprop);
    } else {
      let string = '';
      if (!this.isEntrepreneurship) {
        string = ' propiedades';
      }
      titleText = titleText.concat(string);
    }

    if (!this.isEntrepreneurship) {
      const optypes: any = [];
      optypes['sale'] = 'venta';
      optypes['rent'] = 'alquiler';
      optypes['temporal'] = 'alquiler temporal';
      let stringtype = '';

      for (let index = 0; index < reportResult.operationType.length; index++) {
        if (index == 0) {
          stringtype = ' en ' + optypes[reportResult.operationType[index]];
        } else if (index == reportResult.operationType.length - 1) {
          stringtype += ' y ' + optypes[reportResult.operationType[index]];
        } else {
          stringtype += ' ' + optypes[reportResult.operationType[index]];
        }
      }
      titleText = titleText.concat(stringtype);
    }

    if (this.filterGeoList !== null && this.filterGeoList.length > 0) {
      const LENGTH: number = this.filterGeoList.length;
      for (let i = 0; i < LENGTH; i++) {
        reportResult.ubication += this.filterGeoList[i].label;
        if (i + 2 < LENGTH) {
          reportResult.ubication += ', ';
        } else if (i + 2 == LENGTH) {
          reportResult.ubication += ' y ';
        }
      }
      titleText = titleText.concat(' en ' + reportResult.ubication);
    }
    this.checkedTitle = true;
    return titleText;
  }

  //-------------------------------------

  private setDefaultFilterExpense() {
    switch (environment.node) {
      case 'ar':
        this.filterExpense.currencyId = 2;
        break;
      case 'uy':
        this.filterExpense = new QrFilterPrice();
        this.filterExpense.currencyId = 3;
        break;
      default:
        this.filterExpense.currencyId = 2;
    }
  }

  private updateFilterState(older: boolean, current: boolean) {
    if (!older && current) {
      this.filterCount = this.filterCount + 1;
    } else if (older && !current) {
      this.filterCount = this.filterCount - 1;
    }
    // this.filterOfficeId = null;
    // this._officeName = null;
  }

  /* Cuando la busqueda retorna un resultado
   se guarda el estado de los filtros
   */
  private updateFiltersExcuteState() {
    this.filterOperationExecState = [...this.filterOperation];
    this.filterConditionExecState = [...this.filterCondition];
    this.filterFeaturesExecState = [...this.filterFeatures];
    this.filterListingTypeExecState = [...this.filterListingType];
    this.filterStageExecState = [...this.filterStage];
    this.filterAreEntrepreneurshipsExcludedExecState =
      this.filterAreEntrepreneurshipsExcluded;
    this.filterGeoListExecState = [...this.filterGeoList];
    this.filterPolygonoExecState = this.filterPoligono;
    this.filterPriceExecState = this.filterPrice.clone();
    this.filterExpenseExecState = this.filterExpense.clone();
    this.filterYearBuildExecState = [...this.filterYearBuild];
    this.filterRoomsExecState = [...this.filterRooms];
    this.filterBathroomsExecState = [...this.filterBathrooms];
    this.filterBedroomsExecState = [...this.filterBedrooms];
    this.filterParkingSpaceExecState = [...this.filterParkingSpace];
    this.filterDimensionBuildExecState = this.filterDimensionBuilt.clone();
    this.filterDimensionLandExecState = this.filterDimensionLand.clone();
    this.filterDimensionCoveredExecState = this.filterDimensionCovered.clone();
    this.filterOpportunitiesTypeExecState = [...this.filterOpportunitiesType];
    this.filterAptCreditExecState = this.filterAptCredit;
    this.filterAptProfessionalUseExecState = this.filterAptProfessionalUse;
    this.privatefilterOfficeIdExecState = this.filterOfficeId;
    this.filterOrderByExecState = [...this.filterOrderBy];
    this.pageExecState = this.qrFilter.page;
    this.pageSizeExecState = this.qrFilter.pageSize;
    this.filterCountExecState = this.filterCount;
  }

  //-------------------------------------
  //-------------------------------------

  //-------------------------------------
  //-------------------------------------

  //-------------------------------------
  private setInValueParam(key: any, value: any) {
    switch (key) {
      case 'operationId':
        this.filterOperation = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'eStageId':
        this.filterStage = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'typeId':
        this.filterListingType = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'totalRooms':
        this.filterRooms = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'bathrooms':
        this.filterBathrooms = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'bedrooms':
        this.filterBedrooms = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'parkingSpaces':
        this.filterParkingSpace = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
      case 'opportunityId':
        this.filterOpportunitiesType = value.split(',').map(function (i: any) {
          return parseInt(i, 10);
        });
        break;
    }
  }

  //-------------------------------------
  private setGteValueParam(key: any, value: any) {
    switch (key) {
      case 'yearBuilt':
        switch (value) {
          case '5':
            this.filterYearBuild = [1];
            break;
        }

        break;
      case 'price':
        const filterPrice: QrFilterPrice = this.filterPrice;
        filterPrice.min = +value;
        this.filterPrice = filterPrice;
        break;
      case 'expensesPrice':
        const expensesPrice: QrFilterPrice = this.filterExpense;
        expensesPrice.min = +value;
        this.filterExpense = expensesPrice;
        break;
      case 'totalRooms':
        this.filterRooms = [+value];
        break;
      case 'bathrooms':
        this.filterBathrooms = [+value];
        break;
      case 'bedrooms':
        this.filterBedrooms = [+value];
        break;
      case 'parkingSpaces':
        this.filterParkingSpace = [+value];
        break;
      case 'dimensionLand':
        const dimensionLand: QrFilterRange = this.filterDimensionLand;
        dimensionLand.min = +value;
        this.filterDimensionLand = dimensionLand;
        break;
      case 'dimensionTotalBuilt':
        const dimensionBuild: QrFilterRange = this.filterDimensionBuilt;
        dimensionBuild.min = +value;
        this.filterDimensionBuilt = dimensionBuild;
        break;

      case 'dimensionCovered':
        const dimensionCovered: QrFilterRange = this.filterDimensionCovered;
        dimensionCovered.min = +value;
        this.filterDimensionCovered = dimensionCovered;
        break;
    }
  }

  //-------------------------------------
  private setLteValueParam(key: any, value: any) {
    switch (key) {
      case 'yearBuilt':
        switch (value) {
          case '5':
            this.filterYearBuild = [2]; //new ItemParam(2, 'between5and10years'),
            break;
          case '10':
            this.filterYearBuild = [3]; // new ItemParam(3, 'between10and20years'),
            break;
          case '20':
            this.filterYearBuild = [4]; // new ItemParam(4, 'between20and50years'),
            break;
          case '50':
            this.filterYearBuild = [5]; // new ItemParam(5, 'moreThan50years'),
            break;
        }
        break;
      case 'price':
        const filterPrice: QrFilterPrice = this.filterPrice;
        filterPrice.max = +value;
        this.filterPrice = filterPrice;
        break;
      case 'expensesPrice':
        const expensesPrice: QrFilterPrice = this.filterExpense;
        expensesPrice.max = +value;
        this.filterExpense = expensesPrice;
        break;
      case 'dimensionLand':
        const dimensionLand: QrFilterRange = this.filterDimensionLand;
        dimensionLand.max = +value;
        this.filterDimensionLand = dimensionLand;
        break;

      case 'dimensionTotalBuilt':
        const dimensionBuild: QrFilterRange = this.filterDimensionBuilt;
        dimensionBuild.max = +value;
        this.filterDimensionBuilt = dimensionBuild;
        break;
      case 'dimensionCovered':
        const dimensionCovered: QrFilterRange = this.filterDimensionCovered;
        dimensionCovered.max = +value;
        this.filterDimensionCovered = dimensionCovered;
        break;
    }
  }

  //-------------------------------------
  private setEqValueParam(key: any, value: any) {
    switch (key) {
      case 'currencyId':
        const filterPrice: QrFilterPrice = this.filterPrice;
        filterPrice.currencyId = +value;
        this.filterPrice = filterPrice;
        break;
      case 'expensesCurrencyId':
        const expensesPrice: QrFilterPrice = this.filterExpense;
        expensesPrice.currencyId = +value;
        this.filterExpense = expensesPrice;
        break;
      case 'totalRooms':
        this.filterRooms = [+value];
        break;
      case 'bathrooms':
        this.filterBathrooms = [+value];
        break;
      case 'bedrooms':
        this.filterBedrooms = [+value];
        break;
      case 'parkingSpaces':
        this.filterParkingSpace = [+value];
        break;
      case 'opportunity':
        this.filterOpportunity = [+value];
        break;
      case 'aptCredit':
        this.filterAptCredit = value == 'true';
        break;
      case 'entrepreneurship':
        this.filterAreEntrepreneurshipsExcluded = value == 'false';
        break;
      case 'professionalUse':
        this.filterAptProfessionalUse = value == 'true';
        break;
      case 'remaxCollection':
        this.filterRemaxCollection = value == 'true';
        break;

      case 'privateCommunityId':
        const geo = new GeoSearch();
        const data = GeoSearch.decodeToFromValue(value);
        geo.privateCommunityId = +data.value;
        geo.label = data.label;
        this.filterGeo = geo;
        break;
      case 'neighborhoodsId':
        const geo1 = new GeoSearch();
        const data1 = GeoSearch.decodeToFromValue(value);
        geo1.neighborhoodsId = +data1.value;
        geo1.label = data1.label;
        this.filterGeo = geo1;
        break;
      case 'cityId':
        const geo2 = new GeoSearch();
        const data2 = GeoSearch.decodeToFromValue(value);
        geo2.citieId = +data2.value;
        geo2.label = data2.label;
        this.filterGeo = geo2;
        break;
      case 'countyId':
        const geo3 = new GeoSearch();
        const data3 = GeoSearch.decodeToFromValue(value);
        geo3.countieId = +data3.value;
        geo3.label = data3.label;
        this.filterGeo = geo3;
        break;
      case 'stateId':
        const geo4 = new GeoSearch();
        const data4 = GeoSearch.decodeToFromValue(value);
        geo4.stateId = data4.value;
        geo4.label = data4.label;
        this.filterGeo = geo4;
        break;
      case 'subregionId':
        const geo5 = new GeoSearch();
        const data5 = GeoSearch.decodeToFromValue(value);
        geo5.subregionId = data5.value;
        geo5.label = data5.label;
        this.filterGeo = geo5;
        break;

      case 'officeId':
        this.filterOfficeId = value;
        break;
    }
  }
  //-------------------------------------
}
