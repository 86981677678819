<mat-form-field class="mat-form-field-white-bg" appearance="outline">
  <mat-label>{{ label | translate: locale.language | titlecase }}</mat-label>
  <input
    [ngModel]="visibleValue"
    (keydown)="keyup($event)"
    (input)="input($event)"
    matInput
    (blur)="blur()"
    (paste)="paste($event)" />
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
</mat-form-field>
