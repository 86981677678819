import { Pipe, PipeTransform } from '@angular/core';
import { GeoSearch } from '../components/geo-search/geo-search.model';
import { removeHtml } from '../core/utils/removeHtml';
@Pipe({
  name: 'locationLabel',
  standalone: true,
})
export class LocationLabelPipe implements PipeTransform {
  transform(result: GeoSearch): string {
    let label: string = '';
    if (result.privatecommunitie && result.privatecommunitie.length > 1) {
      label = result.privatecommunitie;
    } else if (result.neighborhood && result.neighborhood.length > 1) {
      label = result.neighborhood;
    } else if (result.citie && result.citie.length > 1) {
      label = result.citie;
    } else if (result.countie && result.countie.length > 1) {
      label = result.countie;
    } else if (result.subregion && result.subregion.length > 1) {
      label = result.subregion;
    } else if (result.state && result.state.length > 1) {
      label = result.state;
    } else if (result.chipLabel) {
      label = result.chipLabel;
    } else if (result.label) {
      label = result.label;
    } else {
      const stringArray = label.split(',');
      label = stringArray[0].trim();
    }
    return removeHtml(label);
  }
}
