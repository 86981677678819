<div class="searcher" [class.searcher--home-searcher]="isHomeSearcher">
  @if (!isLocationsMenuVisible && locationsSelected.length === 1) {
    <div class="searcher__locations">
      <ng-container *ngTemplateOutlet="locations"></ng-container>
    </div>
  }
  @if (!isLocationsMenuVisible && locationsSelected.length > 1) {
    <qr-tag-x
      class="searcher__tag-amount"
      [config]="LOCATIONS_AMOUNT_TAG"
      (click)="openLocationsMenu()"
      ><div class="searcher__amount">
        {{ locationsSelected.length }}
      </div>
      ubicaciones</qr-tag-x
    >
  }
  <input
    class="searcher__input"
    type="text"
    autocomplete="off"
    [placeholder]="'home.searchbar' | translate: locale.language"
    [matAutocomplete]="auto"
    [formControl]="inputFormControl"
    #input
    (click)="openLocationsMenu()"
    (keydown)="keydownChange($event)" />
</div>

<mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  [displayWith]="removeHTMLLabel.bind(this)">
  @for (result of resultsAutocomplete; track result) {
    <mat-option [value]="result.label" (onSelectionChange)="add(result)">
      <div style="width: 400px" [innerHTML]="result.label"></div>
    </mat-option>
  }
</mat-autocomplete>

@if (locationsSelected.length > 0 && isLocationsMenuVisible) {
  <div class="locations">
    <ng-container *ngTemplateOutlet="locations"></ng-container>
  </div>
}

<ng-template #locations>
  @for (location of locationsSelected; track location) {
    <qr-tag-x
      class="tag"
      [id]="location.label"
      [config]="LOCATION_TAG"
      (tagChange)="remove($event)"
      >{{ location | locationLabel | titlecase }}</qr-tag-x
    >
  }
</ng-template>

@if (isLocationsMenuVisible) {
  <div class="overlay" (click)="closeLocationsMenu()"></div>
}
