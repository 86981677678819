import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@base/src/app/core/constants/constants';
import { QrButtonComponent } from '../../qr-button/qr-button.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-invitation-to-register',
  templateUrl: './invitation-to-register.component.html',
  styleUrls: ['./invitation-to-register.component.scss'],
  standalone: true,
  imports: [QrButtonComponent],
})
export class InvitationToRegisterComponent {
  buttonOptions = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Iniciar sesión',
  };

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  public onLogin(): void {
    if (isPlatformBrowser(this.platformId)) {
      const URL: string = window.location.href;
      localStorage.setItem('returnUrl', URL);
      this.router.navigate(['/iniciar-sesion']);
    }
  }
}
