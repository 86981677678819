export function removeHtml(value: string): string {
  if (value != null && value.length > 0) {
    const re1 = new RegExp('<b>', 'g');
    const re2 = new RegExp('</b>', 'g');
    value = value.replace(re1, '');
    value = value.replace(re2, '');
    return value;
  } else {
    return '';
  }
}
