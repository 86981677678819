import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';

import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ItemParam } from '@app/core/models/ItemParam';
import { SearchDomain } from '@app/domain/search.domain';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

export interface Checkbox {
  id: any;
  value: string;
  selected: boolean;
}

@Component({
  selector: 'qr-filter-checkboxs-list',
  templateUrl: './qr-filter-checkboxs-list.component.html',
  styleUrls: ['./qr-filter-checkboxs-list.component.scss'],
  standalone: true,
  imports: [L10nTranslatePipe, FormsModule, MatCheckboxModule],
})
export class QrFilterCheckboxsListComponent implements OnDestroy {
  list: Checkbox[] = [];

  @Output() onchange = new EventEmitter<any>();
  _dataSelected: any[] = [];
  private subscription: Subscription | undefined;

  @Input() set options(data: ItemParam[]) {
    if (data !== undefined && data !== null && data.length > 0) {
      this.list = [];
      data.forEach(item => {
        this.list.push({
          id: item.id,
          value: item.lang,
          selected: false,
        });
      });
    }
  }

  @Input() set selected$(value: Observable<any[]>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (!Object.is(data, this._dataSelected)) {
          this._dataSelected = [...data];
          this.updateSelecte();
        }
      });
    }
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public searchDomain: SearchDomain
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  /*
        updateDataForParent: Envia al componente padre el estado de lo botones
        */
  updateDataForParent(): void {
    this._dataSelected = [];
    this.list.forEach(check => {
      //especificaciones para apto credito y apto profesional (67 y 68 deprecados en el BE 🤷‍♀️)
      if (check.id == 67) {
        check.selected = this.searchDomain.filterAptCredit;
      }
      if (check.id == 68) {
        check.selected = this.searchDomain.filterAptProfessionalUse;
      }
      if (check.selected) {
        this._dataSelected.push(check.id);
      }
    });
    this.onchange.emit([...this._dataSelected]);
  }

  private updateSelecte() {
    if (this._dataSelected) {
      this.list.forEach(check => {
        //especificaciones para apto credito y apto profesional (67 y 68 deprecados en el BE 🤷‍♀️)
        if (check.id == 67) {
          check.selected = this.searchDomain.filterAptCredit;
        } else if (check.id == 68) {
          check.selected = this.searchDomain.filterAptProfessionalUse;
        } else {
          check.selected = this._dataSelected.includes(check.id);
        }
      });
    }
  }
}
