<div class="dropdown-select" [ngClass]="{ open: linkedDropdown }">
  <div class="border"></div>
  @if (this.options && this.options.length !== 0 && !isSelected) {
    <p class="placeholder">
      {{ this.config.placeholder | translate: locale.language }}
    </p>
  }

  @if (isSelected) {
    <div class="selection">
      @if (!isNumberRange) {
        <div>
          @for (item of this.array; track item) {
            <span class="selected">
              {{ item | translate: locale.language | titlecase }}
            </span>
          }
        </div>
      } @else {
        <span class="selected">
          <!-- Desde: XXXX -->
          @if (this.min) {
            <span
              >{{ 'list.from' | translate: locale.language | titlecase }}:
            </span>
          }
          @if (this.min) {
            <span> {{ this.min | qrNumber }}</span>
          }
          <!-- Separador -->
          {{ this.min && this.max ? '-' : '' }}
          <!-- Hasta: XXXX -->
          @if (this.max) {
            <span
              >{{ 'list.to' | translate: locale.language | titlecase }}:
            </span>
          }
          @if (this.max) {
            <span>{{ this.max | qrNumber }}</span>
          }
          @if (!this.max && !this.min) {
            {{ this.config.placeholder | translate: locale.language }}
          }
        </span>
      }
    </div>
  }
  <div class="icon"></div>
</div>
