import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-qr-dialog-more-than-moving',
  templateUrl: './qr-dialog-more-than-moving.component.html',
  styleUrls: ['./qr-dialog-more-than-moving.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class QrDialogMoreThanMovingComponent implements OnInit, OnChanges {
  dataFromParent: any; // Data que le llega desde el componente padre
  backgroundImage = [
    '/assets/images/illustration-test.png',
    '/assets/images/illustration-test.png',
    '/assets/images/illustration-test.png',
    '/assets/images/illustration-test.png',
  ];

  @Output() dataForParent = new EventEmitter<any>();

  //constructor() {}

  ngOnInit() {
    this.backgroundImage = this.dataFromParent.backgroundImage;
  }

  ngOnChanges(): void {
    this.backgroundImage = this.dataFromParent.backgroundImage;
  }
}
