import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtilsService } from '../core/services/http-utils.service';

@Injectable({ providedIn: 'root' })
export class CurrenciesService extends HttpUtilsService {
  constructor(http: HttpClient) {
    super(http, 'currencies/findAll');
  }
}
