<div class="save-search-error">
  <div class="save-search-error__icon">
    <div class="save-search-error__icon-image">
      <img src="assets/icons/alert.svg" alt="Alert" />
    </div>
  </div>
  <h2 class="save-search-error__title">No se ha podido guardar tu búsqueda</h2>
  <p class="save-search-error__message">
    Necesitas elegir
    <span class="save-search-error__message--bold">la ubicación</span> y
    <span class="save-search-error__message--bold">el tipo de propiedad</span>
    que estás buscando.
  </p>
</div>
