import { IQrFilterType } from '../enum/IQrFilterType';

export class QrFilterCustom {
  type: IQrFilterType;
  field: string | null;
  value: any;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(type: IQrFilterType, field: string | null, value: any) {
    this.type = type;
    this.field = field;
    this.value = value;
  }

  toString(): string {
    return (
      'type: ' +
      this.type +
      ' field: ' +
      this.field +
      ' value: ' +
      this.value.toString()
    );
  }
}
