import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { ItemParam } from '@app/core/models/ItemParam';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { CheckboxGroup } from '@app/core/models/checkbox-group.model';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { QrCheckboxGroupComponent } from '../qr-checkbox-group/qr-checkbox-group.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'qr-filter-property-type',
  templateUrl: './qr-filter-property-type.component.html',
  styleUrls: ['./qr-filter-property-type.component.scss'],
  standalone: true,
  imports: [
    TitleCasePipe,
    L10nTranslatePipe,
    MatCheckboxModule,
    FormsModule,
    QrCheckboxGroupComponent,
    MatProgressSpinnerModule,
    CommonModule,
  ],
})
export class QrFilterPropertyTypeComponent
  implements OnInit, OnDestroy, OnChanges
{
  // Listado de tipos de propiedad
  isLoading = true;
  checkboxs: CheckboxGroup[] = [];
  checkboxGroups: CheckboxGroup[] = []; // Listado que contiene todos los checkboxgroups
  subcheckboxGroupDepartment: CheckboxGroup[] = []; // Lista de subcheckboxs de tipo departamento
  subcheckboxGroupHouse: CheckboxGroup[] = []; // // Lista de subcheckboxs de tipo casa
  isEc = environment.node == Constants.NODE_ECUADOR;

  @Output() onchange: EventEmitter<any[]> = new EventEmitter<any[]>();
  public _data: ItemParam[] = [];
  _dataSelected: any[] = [];
  private subscription: Subscription | undefined;

  @Input()
  set options(value: ItemParam[]) {
    if (value !== undefined && value !== null) {
      this._data = value;
      this.init();
      this.updateSelectedEvent();
    }
  }

  @Input() set selected$(value: Observable<any[]>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        if (!Object.is(data, this._dataSelected)) {
          // this._dataSelected = data;
          this._dataSelected = [...data];
          this.updateSelectedEvent();
        }
      });
    }
  }

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}
  ngOnInit(): void {
    // Determinar: si el CheckboxGroup (Checkbox Padre) tiene todos sus hijos checkbox seleccionados
    for (let z = 0; z < this.checkboxGroups.length; z++) {
      const checkboxGroup = this.checkboxGroups[z];
      if (this.checkboxGroups[z].subcheckboxs?.every(t => t.selected)) {
        checkboxGroup.selected = true;
      } else {
        checkboxGroup.selected = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  ngOnChanges(): void {
    if (this.options) {
      for (let z = 0; z < this.checkboxGroups.length; z++) {
        const checkboxGroup = this.checkboxGroups[z];
        const subcheckboxs = checkboxGroup.subcheckboxs;
        if (!subcheckboxs) continue;
        for (let x = 0; x < subcheckboxs.length; x++) {
          const subcheckbox = subcheckboxs[x];
          //
          //subcheckbox.selected = this._dataSelected.includes(subcheckbox.id);
          //
          const matchResult = this.options.find(e => e.id === subcheckbox.id);
          matchResult
            ? (subcheckbox.selected = true)
            : (subcheckbox.selected = false);
        }

        if (this.checkboxGroups[z].subcheckboxs?.every(t => t.selected)) {
          checkboxGroup.selected = true;
        } else {
          checkboxGroup.selected = false;
        }

        // this.checkboxGroups[z] = Object.assign({}, checkboxGroup); // Actualizar objeto
      }

      for (let z = 0; z < this.checkboxs.length; z++) {
        const checkbox = this.checkboxs[z];

        const matchResult = this.options.find(e => e.id === checkbox.id);
        matchResult ? (checkbox.selected = true) : (checkbox.selected = false);
      }
    }

    if (this.options && this.options.length == 0) {
      // Detecto caso de limpiar filtros
      for (let x = 0; x < this.checkboxGroups.length; x++) {
        const checkboxGroup = this.checkboxGroups[x];
        this.checkboxGroups[x] = Object.assign({}, checkboxGroup); // Actualizar objeto
      }
    }
  }

  /*
	loadPropertyTypes: Cargar listado de tipos de propiedad
	*/
  async init(): Promise<void> {
    try {
      /*---- Manejo de checkboxs & subcheckboxs de tipos de propiedad ----*/
      for (let i = 0; i < this._data.length; i++) {
        const propertyType = this._data[i];
        if (propertyType.value.includes('departamento')) {
          // Detectar: subcheckboxs de tipo departamento
          const subcheckboxDepartment: CheckboxGroup = {
            id: propertyType.id,
            value: propertyType.value,
            order: propertyType.order,
            selected: false,
          };
          this.subcheckboxGroupDepartment.push(subcheckboxDepartment);
        } else if (propertyType.value.includes('casa')) {
          // Detectar: subcheckboxs de tipo casa
          const subcheckboxHouse: CheckboxGroup = {
            id: propertyType.id,
            value: propertyType.value,
            order: propertyType.order,
            selected: false,
          };
          this.subcheckboxGroupHouse.push(subcheckboxHouse);
        } else {
          // Detectar: resto de checkboxGroups sin subcheckboxs
          const checkboxGroup: CheckboxGroup = {
            id: propertyType.id,
            value: propertyType.value,
            order: propertyType.order,
            selected: false,
          };
          this.checkboxs.push(checkboxGroup);
        }
      }

      const checkboxGroupDepartment: CheckboxGroup = {
        // Armar: CheckboxGroup + Subcheckboxs
        id: -1,
        value: 'departamento',
        order: 1,
        selected: false,
        subcheckboxs: this.subcheckboxGroupDepartment,
      };

      const checkboxGroupHouse: CheckboxGroup = {
        // Armar: CheckboxGroup + Subcheckboxs
        id: -2,
        value: 'casa',
        order: 5,
        selected: false,
        subcheckboxs: this.subcheckboxGroupHouse,
      };

      this.checkboxGroups.push(checkboxGroupDepartment);
      this.checkboxGroups.push(checkboxGroupHouse);
      this.checkboxGroups.reverse();
      //	this.checkboxs.reverse();
      this.checkboxs = this.checkboxs.sort((a: any, b: any) => {
        if (a.order > b.order) {
          return 1;
        } else {
          return -1;
        }
      });

      // Si hay this.data: Se cargan los filtros aplicados
      this.updateCheckboxs();
      this.isLoading = false;
    } catch (error) {
      console.error(error);
    }
  }

  /*
	updateCheckboxs: Al cargar this.data actualiza los selected de los objetos
	*/
  updateCheckboxs(): void {
    if (this.options) {
      for (let i = 0; i < this.options.length; i++) {
        const item = this.options[i];
        // Checkboxs Groups
        for (let i = 0; i < this.checkboxGroups.length; i++) {
          const checkboxGroup = this.checkboxGroups[i];
          if (checkboxGroup.subcheckboxs == undefined) continue;
          // Subcheckboxs
          for (
            let index = 0;
            index < checkboxGroup.subcheckboxs.length;
            index++
          ) {
            const subcheckboxs = checkboxGroup.subcheckboxs[index];
            if (subcheckboxs.id == item.id) {
              subcheckboxs.selected = true;
            }
          }
        }
        // Checkboxs
        const coincidenceFound = this.checkboxs.find(checkbox => {
          return checkbox.id == item.id;
        });
        if (coincidenceFound) {
          coincidenceFound.selected = true;
        }
      }

      for (let k = 0; k < this.checkboxGroups.length; k++) {
        const checkboxGroup = this.checkboxGroups[k];
        /*
				Se define si el selected del checkboxGroup padre es true o false,
				utilizando .every recorro todos los subcheckboxs, si son todos true
				este sera true tambien caso contrario false
				*/
        checkboxGroup.selected =
          checkboxGroup.subcheckboxs?.every(t => t.selected) || false;
      }
    }
  }

  /*
	checkboxGroupChanged: Se dispara al realizar un cambio en un checkboxgroup
	*/
  checkboxGroupChanged(e: any): void {
    let checkboxGroup = this.checkboxGroups.find(cg => {
      return cg.id == e.id;
    });
    checkboxGroup = e; // Actualizar datos de checkboxGroup
    if (!checkboxGroup) {
      return;
    }
    this.checkboxGroups.forEach(element => {
      if (checkboxGroup && element.id == checkboxGroup.id) {
        element = checkboxGroup;
      }
    });
    this.updateDataForParent();
  }

  /*
	checkboxChanged: Se dispara al realizar un cambio en un checkbox
	*/
  checkboxChanged(cg: CheckboxGroup): void {
    this.checkboxs.map(checkbox => {
      if (checkbox.id == cg.id) {
        checkbox = Object.assign({}, cg);
      }
    });
    this.updateDataForParent();
  }

  //Cuando selected$ que esta escuchando cambios recibe un evento actualiza los check
  updateSelectedEvent(): void {
    if (this._dataSelected) {
      // Checkboxs Groups
      for (let i = 0; i < this.checkboxGroups.length; i++) {
        const checkboxGroup = this.checkboxGroups[i];
        if (checkboxGroup.subcheckboxs == undefined) continue;
        // Subcheckboxs
        for (
          let index = 0;
          index < checkboxGroup.subcheckboxs.length;
          index++
        ) {
          const subcheckboxs = checkboxGroup.subcheckboxs[index];
          subcheckboxs.selected = this._dataSelected.includes(subcheckboxs.id);
        }
      }
      // Checkboxs
      for (let i = 0; i < this.checkboxs.length; i++) {
        const checkbox = this.checkboxs[i];
        checkbox.selected = this._dataSelected.includes(checkbox.id);
      }
    }
  }

  //updateDataForParent: Envia al componente padre que checkboxs estan seleccionados
  updateDataForParent(): void {
    this._dataSelected = []; // Reiniciar array

    // Checkboxs Groups
    for (let i = 0; i < this.checkboxGroups.length; i++) {
      const checkboxGroup = this.checkboxGroups[i];
      if (checkboxGroup.subcheckboxs == undefined) continue;
      // Subcheckboxs
      for (let index = 0; index < checkboxGroup.subcheckboxs.length; index++) {
        const subcheckboxs = checkboxGroup.subcheckboxs[index];

        //Validación para ECUADOR
        //(EC no tiene subtipos de prop en la teoría pero en la practica el tipo "casa" y "departamento" traen su subCheckox con 1 elemento -casa o depto-)
        if (environment.node == Constants.NODE_ECUADOR) {
          checkboxGroup.subcheckboxs[index].selected = checkboxGroup.selected
            ? true
            : false;
        }

        //Lo agrega si está seleccionado
        if (subcheckboxs.selected) {
          this._dataSelected.push(subcheckboxs.id);
        }
      }
    }

    // Checkboxs
    for (let i = 0; i < this.checkboxs.length; i++) {
      const checkbox = this.checkboxs[i];
      if (checkbox.selected) {
        this._dataSelected.push(checkbox.id);
      }
    }
    this.onchange.emit([...this._dataSelected]);
  }
}
